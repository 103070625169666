import { Linking } from "react-native";
import React from "react";
import { Avatar, Card, IconButton } from "react-native-paper";
import { Leilao } from "../../../utils/types/Leilao.type";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useNavigation } from "@react-navigation/native";
import { theme } from "../../../Global/Theme";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
const urlLogo = 'https://i.ibb.co/NsPhG1W/logo-macedo.png';
type IProps = {
  leilao: Leilao;
};


export type RootStackParamList = {
  SaleScreen: { leilao: Leilao } | undefined;
};

export default function CardLeilaoReduzido({ leilao }: IProps) {
  const { innerWidth: width, innerHeight: height } = window;
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const handlePress = () => {
    navigation.navigate("SaleScreen", {
      leilao,
    })
  };
  var formatter = new Intl.DateTimeFormat('pt-BR');
  var date = new Date(leilao.DATLEI);

  const larguraCard = () => {    
    if (innerWidth <  640) {
      return innerWidth / 3
    } else {
      return '150px'
    }
  };

  const alturaCard = () => {    
    if (innerWidth <  640) {
      return innerHeight - 250
    } else {
      return 'px'
    }
  };

  return (
    <Card
      onPress={handlePress}
      theme={{ roundness: 0 }}
      style={{
        marginVertical: 0,
        marginTop: '10px',
        borderRadius: 12,
        backgroundColor: theme.colors.secondary,
        width: larguraCard(),
        height: '150px',
      }}
    >      

      <Card.Cover        
        resizeMode="cover"
        style={{          
          marginLeft: 5,          
          backgroundColor: theme.colors.primary,
          height: '150px',
          width: '95%',          
          borderRadius: 12,
        }}
        defaultSource={{ uri: urlLogo }}
        source={{
          uri:
            "https://macedo.s3.us-east-2.amazonaws.com/mobile_leilao_img_" +
            leilao.ID +
            ".png" +
            "?timestamp=" + new Date().getTime() ||
            "https://lolitajoias.com.br/wp-content/uploads/2020/09/no-image.jpg",
        }}
      />
      <Card.Actions style={{ justifyContent: "flex-start" }}>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("SaleScreen", {
              leilao,
            })
          }
        >          
        </TouchableOpacity>
      </Card.Actions >
    </Card >
  );
}
