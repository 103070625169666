import { useEffect, useRef } from 'react';
import { Mixpanel } from 'mixpanel-react-native';

const useMixpanel = (token) => {
  const mixpanelRef = useRef(null);

  useEffect(() => {
    // Inicialize o Mixpanel    
    const useNative = false;
    const mixpanel = new Mixpanel(
      token,
      false,
      useNative
    );
    !mixpanelRef.current
      ? mixpanel.init()
      : mixpanelRef.current.init();
    
    mixpanel.setLoggingEnabled(true);
  
    mixpanel.init().then(() => {      
      mixpanelRef.current = mixpanel;
    }).catch((error) => {      
    });
  }, [token]);

  // Função para rastrear eventos
  const trackEvent = (eventName, properties) => {
    if (mixpanelRef.current) {
      mixpanelRef.current.track(eventName, properties);
    } else {
      console.error('Mixpanel is not initialized yet');
    }
  };

  // Retorne a função de rastreamento
  return { trackEvent };
};

export default useMixpanel;
