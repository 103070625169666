import React, { useState } from 'react';
import { View, Text, TextInput, Button, StyleSheet, Alert } from 'react-native';
import { signUp } from '../Services/api/AuthServices'; // Assume this function exists to handle the API call

const RegisterClient = ({ onClose }) => {
    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');
    const [cidade, setCidade] = useState('');
    const [loading, setLoading] = useState(false);

    const formatCPF = (input) => {
        // Remove all non-numeric characters
        const onlyDigits = input.replace(/\D/g, '');

        // Format the CPF as ###.###.###-##
        if (onlyDigits.length <= 3) return onlyDigits;
        if (onlyDigits.length <= 6) return `${onlyDigits.slice(0, 3)}.${onlyDigits.slice(3)}`;
        if (onlyDigits.length <= 9) return `${onlyDigits.slice(0, 3)}.${onlyDigits.slice(3, 6)}.${onlyDigits.slice(6)}`;
        return `${onlyDigits.slice(0, 3)}.${onlyDigits.slice(3, 6)}.${onlyDigits.slice(6, 9)}-${onlyDigits.slice(9, 11)}`;
    };

    const handleRegister = async () => {
        if (!nome || !cpf || !email || !cidade) {
            Alert.alert('Erro', 'Todos os campos são obrigatórios.');
            return;
        }

        setLoading(true);
        try {
            const newClient = { NOMEXX: nome, CPFXXX: cpf.replace(/\D/g, ''), EMAILX: email, CIDADE: cidade }; // Send only digits
            const response = await signUp(newClient);
            if (response) {
                Alert.alert('Sucesso', 'Cliente cadastrado com sucesso!');
                onClose();
            }
        } catch (error) {
            Alert.alert('Erro', 'Falha ao cadastrar cliente. Tente novamente.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <View style={styles.container}>
            <Text style={styles.title}>Cadastrar Cliente</Text>
            <TextInput
                style={styles.input}
                placeholder="Nome"
                value={nome}
                onChangeText={setNome}
            />
            <TextInput
                style={styles.input}
                placeholder="CPF"
                value={formatCPF(cpf)}
                onChangeText={(text) => setCpf(formatCPF(text))}
                keyboardType="numeric"
            />
            <TextInput
                style={styles.input}
                placeholder="Email"
                value={email}
                onChangeText={setEmail}
                keyboardType="email-address"
            />
            <TextInput
                style={styles.input}
                placeholder="Cidade"
                value={cidade}
                onChangeText={setCidade}
            />
            <Button title={loading ? "Cadastrando..." : "Cadastrar"} onPress={handleRegister} disabled={loading} />
            <Button title="Cancelar" onPress={onClose} color="red" />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 16,
        backgroundColor: '#fff',
        borderRadius: 8,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 16,
    },
    input: {
        height: 40,
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 8,
        marginBottom: 12,
    },
});

export default RegisterClient;
