import { Linking, Text, Image, View } from "react-native";
import React, { useEffect, useState } from "react";

import {
  ActivityIndicator, Appbar,
  Modal,
} from "react-native-paper";
import { Leilao } from "../../utils/types/Leilao.type";
import CardLeilao from "../../components/Cards/CardLeilao/CardLeilao";
import { ScrollView } from "react-native-gesture-handler";
import { getLeiloes, getUser } from "../../Services/api/AuthServices";
import { theme } from "../../Global/Theme";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import Menu from "../../components/Gerais/Menu/Menu";

import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.css';
import CardLeilaoReduzido from "../../components/Cards/CardLeilao/CardLeilaoReduzido";
import { useCookies } from "react-cookie";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import moment from "moment";
import useMixpanel from "../../hooks/useMixpanel";
import { Button, Tooltip, Typography } from "@mui/material";

export default function HomeScreen({ navigation }) {
  const { innerWidth: width, innerHeight: height } = window;
  const [isLoading, setIsLoading] = useState(false);
  const [ openPopup, setOpenPopup ] = useState(true);
  const [error, setError] = useState(false);
  const [leiloes, setLeiloes] = useState<Leilao[] | null>([]);
  const [cookies, setCookie] = useCookies(["macedo"]);
  const { setState, state } = React.useContext(AuthContext);
  const { trackEvent } = useMixpanel('d70609b52efef69ee6459b93c344f0c9');

  const tamanhoBotoes = () => 
    innerWidth < 640 ? "30px" : "40px";

  const handleFacebook = () => {    
    Linking.openURL('https://www.facebook.com/profile.php?id=100016186750392');
  }

  const handleInstagram = () => {
    Linking.openURL('http://www.instagram.com/macedoleiloes/');
  }

  const handleYoutube = () => {
    Linking.openURL('http://www.youtube.com/c/MacedoLeilõesRurais');
  }

  setTimeout(() => {
    setOpenPopup(false);
  },10000);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", async () => {
      try {
        setIsLoading(false);
        const data = await getLeiloes();
        function compare(a,b) {
          const data1 = moment(a.DATLEI).format('MM-DD-YYYY');
          const data2 = moment(b.DATLEI).format('MM-DD-YYYY');
          return  data1 > data2 ? 1 : -1;
        }
        
        const reverseData = data.sort(compare);
        setLeiloes(reverseData);
        setIsLoading(false);
        trackEvent('Buscando Leiões', { Screen: 'HomeScreen' });
      } catch (error) {
        setIsLoading(false);
        setError(true);
      }
    });
    return unsubscribe;
  }, [navigation]);


  useEffect(() => {
    const fetchData = async () => {
      const meuCookies = cookies.macedo;
      if (meuCookies) {        
        const user = await getUser(cookies.macedo);        
        setState({ user, loggedIn: true, token: '' });
      }
    }
    fetchData()
      .catch(console.error);
  }, []);


  return (
    <View style={{ flex: 1 }} >
      <Menu/>
      {isLoading ? (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <ActivityIndicator animating={true} color="green" />
          <Text>Buscando Leilões...</Text>
        </View>
      ) : (
        
        <View style={{ flex: 1, minHeight: '800px' }}>
         <Appbar.Header
          mode="center-aligned"
          style={{
            backgroundColor: theme.colors.secondary,
            height: '35px',
            width: '100%',          
            justifyContent: "flex-end",
            alignItems: "flex-end"
          }}
        >
          

            <Tooltip
              title="Baixe já o App em sua AppStore"
            >
              <img
                src={require('../../assets/image/icon-appstore.png')}
                alt="AppStore"
                style={{
                  width: tamanhoBotoes(),
                  height: tamanhoBotoes(),
                  margin: '10px'
                }}
                onClick={() => Linking.openURL('https://apps.apple.com/br/app/macedo-leil%C3%B5es-rurais/id1557897528')}
              />            
            </Tooltip>
            <Tooltip
              title="Baixe já o App em sua Google Play"
            >
              <img
                src={require('../../assets/image/icon-googleplay.png')}
                alt="AppStore"
                style={{
                  width: tamanhoBotoes(),
                  height: tamanhoBotoes(),
                  margin: '10px'
                }}
                onClick={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.FRVSoftware.MacedoLR&hl=pt_BR')}
              />            
            </Tooltip>
            <Tooltip
              title="Acesse o Facebook da Macedo Leilões Rurais"
            >
              <img
                src={require('../../assets/image/icon-facebook.png')}              
                style={{
                  width: tamanhoBotoes(),
                  height: tamanhoBotoes(),
                  margin: '10px',
                  borderRadius: 8,
                }}
                onClick={() => handleFacebook()}
              />            
            </Tooltip>
            <Tooltip
              title="Acesse o Instagram da Macedo Leilões Rurais"
            >
              <img
                src={require('../../assets/image/icon-instagram.png')}              
                style={{
                  width: tamanhoBotoes(),
                  height: tamanhoBotoes(),
                  margin: '10px',
                  borderRadius: 8,
                }}
                onClick={() => handleInstagram()}
              />            
            </Tooltip>
            <Tooltip
              title="Acesse o Youtube da Macedo Leilões Rurais"
            >
            <img
                src={require('../../assets/image/icon-youtube.png')}              
                style={{
                  width: tamanhoBotoes(),
                  height: tamanhoBotoes(),
                  margin: '10px',
                  borderRadius: 8,
                }}
                onClick={() => handleYoutube()}
              />            
            </Tooltip>         
          </Appbar.Header>

          <ScrollView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
            style={{
              backgroundColor: theme.colors.secondary,
              minHeight: '400px',
              flex: 1
            }}>
              
              

            {error ? (
              <>
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ActivityIndicator animating={true} color="white" />
                  <Text>Tivemos problemas.Tente novamente...</Text>
                </View>
              </>
            ) : (
              <Carousel>
                {leiloes.map((lote) => (
                  <Carousel.Item style={{ marginTop: innerWidth < 600 && 10, width: innerWidth < 600 ? innerWidth - 50 : '500', height: innerWidth < 600 ? innerWidth - 50 : '500' }}>
                    <CardLeilao key={lote.ID} leilao={lote} route={'home'} />
                  </Carousel.Item>
                ))}
              </Carousel>
              
            )}
          </ScrollView>
          <ScrollView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
            style={{
              backgroundColor: theme.colors.secondary,
              flex: 1
            }}>
            {leiloes.map((lote) => (
              <CardLeilaoReduzido key={lote.ID} leilao={lote} />
            ))}            
          </ScrollView>
          <HeaderBotton></HeaderBotton>                    
        </View>
      )}
      <Modal
        visible={openPopup}
        onDismiss={() => setOpenPopup(false)}
        contentContainerStyle={{ 
          width: innerWidth < 500 ? '80%' : '400px',
          height: innerWidth < 500 ? '50%' : '300px',
          backgroundColor: 'silver', 
          padding: 10,
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: 12,
        }}
      >
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
      <Image 
        style={{                 
          width: '150px', 
          height: '150px', 
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '10px',
          marginBottom: '10px',   
          borderRadius: 12,       
        }}             
        source={
          {
            uri: require('../../../assets/icon.png'),
          }
        }
      />   
      <Typography 
        style={{ 
          marginLeft: 'auto',
          marginRight: 'auto',
          fontFamily: 'Lato, sans-serif', 
          color: 'white', 
          fontWeight: 'bold', 
          fontSize: '20px',
          textAlign: 'center',                   
        }}        
      >
        Baixe o app Macedo Leilões Rurais
      </Typography>  
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '-px',
        }}
      >       
      <Button onClick={() => Linking.openURL('https://apps.apple.com/br/app/macedo-leil%C3%B5es-rurais/id1557897528')}>        
        <Image
          source={require('../../assets/image/app-store.png')}
          style={{
            width: '150px',
            height: '40px',
            margin: '20px',
            borderRadius: 12,
          }}        
        />
      </Button>
      <Button onClick={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.FRVSoftware.MacedoLR&hl=pt_BR')}>        
        <Image
          source={require('../../assets/image/google-play.png')}
          style={{
            width: '150px',
            height: '40px',
            margin: '20px',
            borderRadius: 12,
          }}
        />
      </Button>
      </View>                
      </Modal>
    </View>
  );
}
