import React, { FunctionComponent, ReactNode } from "react";
import { AuthContextProvider } from "./AuthContext/AuthContext";

interface BaseLayoutProps {
  children?: ReactNode;
}

const GlobalContext: FunctionComponent<BaseLayoutProps> = ({ children }) => (
  <AuthContextProvider>{children}</AuthContextProvider>
);

export default GlobalContext;
