import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, ActivityIndicator, StyleSheet, TextInput, Button } from 'react-native';
import { getClientes, createCliente } from '../Services/api/AuthServices'; // Import your createCliente function
import RegisterClient from './RegisterClient'; // Import your new component

export const ListClients = ({ onClientSelect }) => {
    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [showRegister, setShowRegister] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await getClientes();
            if (response) {
                setClientes(response);
            } else {
                console.error('Formato de resposta inesperado:', response);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const filteredClientes = clientes.filter(client => {
        const nome = client.NOMEXX ? client.NOMEXX.toLowerCase() : '';
        const cpf = client.CPFXXX ? client.CPFXXX : '';
        return nome.includes(searchText.toLowerCase()) || cpf.includes(searchText);
    });

    const handleSelectClient = (client) => {
        onClientSelect(client.ID);
    };

    return (
        <View style={styles.container}>
            <TextInput
                style={styles.searchInput}
                placeholder="Buscar por Nome ou CPF"
                value={searchText}
                onChangeText={setSearchText}
            />
            <Button title="Adicionar Cliente" onPress={() => setShowRegister(true)} />
            
            {loading ? (
                <ActivityIndicator size="large" color="#0000ff" />
            ) : (
               !showRegister && (
                <FlatList
                    data={filteredClientes}
                    keyExtractor={(item) => item.ID.toString()}
                    renderItem={({ item }) => (                        
                        <View style={styles.item}>
                            <Text style={styles.itemText}>ID: {item.ID}</Text>
                            <Text style={styles.itemText}>Nome: {item.NOMEXX}</Text>
                            <Text style={styles.itemText}>CPF: {item.CPFXXX || item.CNPJXX}</Text>
                            <Text style={styles.itemText}>Email: {item.EMAILX}</Text>
                            <Button
                                title="Escolher Cliente"
                                onPress={() => handleSelectClient(item)}
                            />
                        </View>
                    )}
                />
               )
            )}

            {showRegister && (
                <RegisterClient onClose={() => setShowRegister(false)} />
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '500px',
        flex: 1,
        padding: 16,
        backgroundColor: '#f8f9fa',
    },
    searchInput: {
        height: 50,
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 5,
        padding: 4,
        marginBottom: 16,
    },
    item: {
        padding: 16,
        marginVertical: 8,
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 5,
    },
    itemText: {
        fontSize: 16,
    },
});
