import axios from "axios";

export const getIp = async () => {
  const res = await axios.get("https://api.ipify.org/?formt=json")  
  return res.data.ip;
}

const API_BASE_URL =
  "https://proxy.cors.sh/http://ec2-3-20-102-1.us-east-2.compute.amazonaws.com:8443/api/";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",    
    "Accept": "application/json",
    "x-cors-api-key" : "live_3ac4e21656f9c8673eca8a9cc573ad7654b609901c249f2b1f04c6f2bd79fa9c",
    "Access-Control-Allow-Origin": "*",
  },
});

export default axiosInstance;
