import { Grid } from "@mui/material";
import CardHeaderBottom from './CardHeaderBottom';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext/AuthContext';
import CardPerfilHeaderBottom from './CardPerfilHeaderBottom';
import { theme } from '../../Global/Theme';
import CardCotacoesHeaderBottom from './CardCotacoesHeaderBottom';

const urlImagem = 'https://i.ibb.co/4sJp53Q/barra-inferior.png';

export default function HeaderBotton() {
  const { setState, state } = useContext(AuthContext);
  const { innerWidth: width, innerHeight: height } = window;
  const isMobile = () => {    
    if (innerWidth <  640) {
      return true
    } else {
      return false
    }
  };

  return (
    <span style={{
      position: 'relative',
      width: '100%',
      height: '200px',
      background: `url(${urlImagem})`,
      backgroundRepeat: 'no-repeat',
    }}
    >

      <Grid container>
        <Grid item style={{ marginTop: 5, marginLeft: '10px', width: isMobile() ? '40%' : '30%' }}>
          {state &&
            <CardPerfilHeaderBottom isMobile={isMobile()} id={state?.user.id} nome={state?.user.nomexx} email={state?.user.emailx} cpf={state?.user.cpfxxx} />
          }
        </Grid>

        {!isMobile() &&
          <Grid item style={{ marginTop: 5, width: isMobile() ? '0px' : '29%' }}>
            <CardCotacoesHeaderBottom isMobile={isMobile()} />
          </Grid>
        }

        <Grid item style={{ marginTop: 5, width: isMobile() ? '50%' : '40%' }}>
          <CardHeaderBottom isMobile={isMobile()} cor={theme.colors.secondary} type="phone" nome={"ESCRITÓRIO"} contato={"(53) 30351977"} />
          <CardHeaderBottom isMobile={isMobile()} cor={theme.colors.secondary} type="whatsapp" nome={"SANTIAGO MACEDO"} contato={"(53) 99999.8901"} contatoTratado={"53999998901"}/>
          <CardHeaderBottom isMobile={isMobile()} cor={theme.colors.secondary} type="whatsapp" nome={"MARCELO FONSECA"} contato={"(53) 99906.5590"} contatoTratado={"53999065590"}/>
          <CardHeaderBottom isMobile={isMobile()} cor={theme.colors.secondary} type="google-maps" nome={"RUA JAGUARÃO, 542 - CASSINO"} contato={"RIO GRANDE/RS | 96205-060"} />
        </Grid>
      </Grid>
    </span>
  )
}

