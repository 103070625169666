import React, { useEffect, useState, useRef } from 'react';
import { View, Text, FlatList, StyleSheet, Button } from 'react-native';
import DraggableItem from './DraggableItem';
import SaleModal from './SaleModal';
import { getLotesparaOrdem, getMediasporLeilao } from '../Services/api/AuthServices';
import OrdemPrint from './Prints/OrdemPrint';

const SPACING_TOP = 20;

const DraggableList = ({ leilao }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const flatListRef = useRef(null);
  const [modalVendaVisible, setModalVendaVisible] = useState(false);
  const [time, setTime] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [totalFaturamento, setTotalFaturamento] = useState(0);
  const [medias, setMedias] = useState([]);
  const [isTotalsVisible, setIsTotalsVisible] = useState(false);

  useEffect(() => {
    if (leilao) {
      getData(leilao.ID);
      getMedias(leilao.ID);
    }
  }, [leilao.ID]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const getData = async (id) => {
    try {
      setLoading(true);
      const response = await getLotesparaOrdem(id);
      if (response) {
        response.sort((a, b) => a.LOTEXX - b.LOTEXX);
        setData(response);
      } else {
        console.error('Formato de resposta inesperado:', response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getMedias = async (id) => {
    try {
      setLoading(true);
      const response = await getMediasporLeilao(id);
      if (response) {
        setMedias(response);
        calculateTotalFaturamento(response);
      } else {
        console.error('Formato de resposta inesperado:', response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotalFaturamento = (items) => {
    const total = items.reduce((sum, item) => sum + item.TOTGERAL, 0);
    setTotalFaturamento(total);
  };

  const moveUp = (index) => {
    if (index > 0) {
      const newData = [...data];
      const item = newData.splice(index, 1)[0];
      newData.splice(index - 1, 0, item);
      setData(newData);
      flatListRef.current.scrollToIndex({ index: index - 1, animated: true });
    }
  };

  const moveDown = (index) => {
    if (index < data.length - 1) {
      const newData = [...data];
      const item = newData.splice(index, 1)[0];
      newData.splice(index + 1, 0, item);
      setData(newData);
      flatListRef.current.scrollToIndex({ index: index + 1, animated: true });
    }
  };

  const handleIniciarVenda = (item) => {
    setSelectedItem(item);
    setModalVendaVisible(true);
  };

  const closeModal = () => {
    setModalVendaVisible(false);
    setSelectedItem(null);
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <View style={{ flex: 1 }}>
      {loading ? (
        <View style={styles.loadingContainer}>
          <Text>Carregando...</Text>
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          <OrdemPrint
            lotes={data}
            totalFaturamento={totalFaturamento} 
            medias={medias} 
            isTotalsVisible={isTotalsVisible} 
            setIsTotalsVisible={setIsTotalsVisible} 
          />
          <FlatList
            ref={flatListRef}
            contentContainerStyle={[styles.list, { paddingTop: SPACING_TOP }]}
            data={data}
            renderItem={({ item, index }) => (
              <DraggableItem
                item={item}
                index={index}
                moveUp={moveUp}
                moveDown={moveDown}
                onIniciarVenda={() => handleIniciarVenda(item)}
              />
            )}
            keyExtractor={(item) => item.ID.toString()}
          />
          <SaleModal
            visible={modalVendaVisible}            
            closeModal={closeModal}
            selectedItem={selectedItem}
            formatTime={formatTime}
            time={time}
            leilao={leilao}
            medias={medias}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  list: {
    padding: 16,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default DraggableList;
