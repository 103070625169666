import { MD3LightTheme as DefaultTheme } from "react-native-paper";

export const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: "#a98722",
    secondary: "#FFFFFF",
    tertiary: "#fec727",
  },
};