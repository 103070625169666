import React, { useState } from "react";
import {
  ScrollView,
  StyleSheet,
  View,
} from "react-native";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { SignUpSchema } from "../../utils/YupSchema/SignUpSchema";
import TextInputForm from "../../components/TextInputForm/TextInputForm";
import { Appbar, Button, IconButton, Modal, Text } from "react-native-paper";
import { checkDocument, getCity, getUser, signUp } from "../../Services/api/AuthServices";
import { useNavigation } from "@react-navigation/native";
import { theme } from "../../Global/Theme";
import Menu from "../../components/Gerais/Menu/Menu";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import { Typography } from "@mui/material";
import { PaperSelect } from 'react-native-paper-select';
import AWS from "aws-sdk";
import { sendEmail } from "../../components/Email/Email";
import { isMobile } from "../../Global/isMobile";
import axios from "axios";
import AlertModal from "../../components/Alerts/AlertModal";
import ArticleIcon from "@mui/icons-material/Article";

export default function SignUpScreens() {  
  const [loading, setLoading] = useState(false);  
  const [info, setInfo] = useState('');
  const navigation = useNavigation<any>()

  const [fileDocumento, setFileDocumento] = useState(null);
  const [fileRenda, setFileRenda] = useState(null);
  const [fileResidencia, setFileResidencia] = useState(null);
  const [disableEstado, setDisableEstado] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleSucesso, setModalVisibleSucesso] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const showModalSucesso = () => {
    setModalVisibleSucesso(true);
  };

  const closeModalSucesso = () => {
    setModalVisibleSucesso(false);
  };

  const [estado, setEstados] = useState({
    value: '',
    list: [
      { _id: 'AP', value: 'AP' },
      { _id: 'AM', value: 'AM' },
      { _id: 'BA', value: 'BA' },
      { _id: 'CE', value: 'CE' },
      { _id: 'GO', value: 'GO' },
      { _id: 'ES', value: 'ES' },
      { _id: 'MA', value: 'MA' },
      { _id: 'MT', value: 'MT' },
      { _id: 'MS', value: 'MS' },
      { _id: 'MG', value: 'MG' },
      { _id: 'PA', value: 'PA' },
      { _id: 'PB', value: 'PB' },
      { _id: 'PR', value: 'PR' },
      { _id: 'PE', value: 'PE' },
      { _id: 'PI', value: 'PI' },
      { _id: 'PJ', value: 'RJ' },
      { _id: 'RN', value: 'RN' },
      { _id: 'RS', value: 'RS' },
      { _id: 'RO', value: 'RO' },
      { _id: 'RR', value: 'RR' },
      { _id: 'SC', value: 'SC' },
      { _id: 'SP', value: 'SP' },
      { _id: 'SE', value: 'SE' },
      { _id: 'TO', value: 'TO' },
      { _id: 'DF', value: 'DF' },
    ],
    selectedList: [],
    error: '',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(SignUpSchema),
  });
  const [tipoPessoa, setTipoPessoa] = useState('F');

  const larguraPercent = () => {
    if (innerWidth < 640) {
      return '100%'
    } else {
      return '60%'
    }
  };

  function getExtension(path) {
    var r = /\.([^./]+)$/.exec(path);
    return r && r[1] || '';
  }

  const uploadFile = async (file, tipoDoc, id) => {
    const ext = getExtension(file.name);
    const S3_BUCKET = "macedo";
    const REGION = "us-east-2";

    AWS.config.update({
      accessKeyId: "AKIAXXGBEYM5VLDEQTLK",
      secretAccessKey: "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",
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: tipoDoc + id + '.' + ext,
      Body: file,
    };

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
      })
      .promise();

    await upload.then((err) => {      
      alert("Comprovante enviado com sucesso!!!");
    });
  };


  const handleFileDocumentoChange = (e) => {
    setFileDocumento(e.target.files[0]);
  };

  const handleFileRendaChange = (e) => {
    setFileRenda(e.target.files[0]);
  };

  const handleFileResidenciaChange = (e) => {
    setFileResidencia(e.target.files[0]);
  };

  const TextDatNas = (props) => (
    <TextInputForm
      name="datnas"
      mode="flat"
      control={control}
      error={errors.datnas}
      keyboardType="none"
      autoCapitalize="none"
      autoCorrect={false}
      label={"Data de Nascimento"}
      style={styles.textInput}
    />
  );

  const TextRgCnh = (props) => (
    <TextInputForm
      name="rg"
      mode="flat"
      keyboardType="number-pad"
      control={control}
      error={errors.rg}
      textContentType="none"
      label={"RG/CNH - Somente números"}
      style={styles.textInput}
    />
  );
  
  function pesquisacep(valor) {    
    var cep = valor.replace(/\D/g, '');    
    if (cep != "") {      
      var validacep = /^[0-9]{8}$/;

      if(validacep.test(cep)) {
        setDisableEstado(true);
      } else {        
        setDisableEstado(false);
        return;
      }

      axios
      .get('https://viacep.com.br/ws/' + cep + '/json/')
      .then(function(response) {
        if (response.data.erro == "true") {
          setDisableEstado(false);
        } else
        {
          setValue('endereco', response.data.logradouro);
          setValue('bairro', response.data.bairro);
          setValue('cidade', response.data.localidade); 
          setEstados({
            ...estado,
            value: response.data.uf,
            error: '',
          });
          setDisableEstado(false);  
        }
      })
      .catch(function(error) {
          console.log(error)
      }) 
    }
  };  
  
  async function handleUserRegister(values) {
    try {
      setLoading(true);
      const user = await checkDocument(values.cpfcgc);

      if (user.id != 0) {
        setLoading(false);
        showModal();           
        return;
      }

      const city = await getCity(values.cidade);      
      values.cidade = parseInt(city.id);      
      await signUp(values);

      const userSaved = await checkDocument(values.cpfcgc);

      { fileDocumento  
        ? await uploadFile(fileDocumento, 'documento_user_', userSaved.id)
        : null; }        
      { fileRenda ? 
        await uploadFile(fileRenda, 'renda_user_', userSaved.id) 
        : null; }
      { fileResidencia ? 
        await uploadFile(fileResidencia, 'residencia_user_', userSaved.id) 
        : null }
  
      showModalSucesso();
      setLoading(false);
      sendEmail('contato@macedoleiloesrurais.com.br','Novo cliente cadastrado: ' + values.nome,'Novo cadastro de cliente inserido via WEBSITE','','')
      sendEmail('marcelosole@macedoleiloesrurais.com.br','Novo cliente cadastrado: ' + values.nome,'Novo cadastro de cliente inserido via WEBSITE','','')            
  
      
      return;
    } catch (error) {
      setLoading(false);
      setInfo("Desculpe!!!Tivemos problemas ao realizar o cadastramento, tente novamente")
    }
  }
  return (

    <View style={{ backgroundColor: theme.colors.secondary, flex: 1, minHeight: '1000px' }}>
      <Menu/>
      <Appbar
        style={{
          height: '70px',
          width: '100%',
          backgroundColor: theme.colors.secondary,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <ArticleIcon
          style={{
            color: theme.colors.tertiary,
            borderRadius: 50,   
            margin: 10         
          }}          
          />
        <Typography color={'black'}>CADASTRO</Typography>
      </Appbar>
      <Text
        style={{
          textAlign: "center",
          paddingTop: 5,
          marginLeft: 10,
          marginBottom: 25,
          color: 'black'
        }}
        variant="titleSmall"
      >
        Seu Cadastro possibilitará você de participar dos leilões on-line e ainda enviar propostas aos animais permanentemente à venda.
      </Text>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button
          mode="contained"
          loading={loading}
          buttonColor={theme.colors.primary}
          textColor={theme.colors.secondary}
          style={{ width: isMobile() ? '45vw' : '30vw', marginLeft: 10, marginBottom: 10 }}
          onPress={() => setTipoPessoa('F')}
        >
          PESSOA FISICA
        </Button>
        <Button
          mode="contained"
          loading={loading}
          buttonColor={theme.colors.primary}
          textColor={theme.colors.secondary}
          style={{ width: isMobile() ? '45vw' : '30vw', marginLeft: 10, marginBottom: 10 }}
          onPress={() => setTipoPessoa('J')}
        >
          PESSOA JURIDICA
        </Button>
      </div>


      <ScrollView
        contentContainerStyle={{
          paddingBottom: 20,
          width: larguraPercent(),
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <TextInputForm
          name="nome"
          mode="flat"
          control={control}
          error={errors.nome}
          keyboardType="none"
          autoCapitalize="none"
          autoCorrect={false}
          label={tipoPessoa == "F" ? "Nome Completo" : "Razão Social"}
          style={styles.textInput}
        />
        {tipoPessoa == "F" ? <TextDatNas /> : null}
        <TextInputForm
          name="email"
          mode="flat"
          control={control}
          error={errors.email}
          keyboardType="email-address"
          textContentType="emailAddress"
          autoCapitalize="none"
          autoCorrect={false}
          label="E-mail"
          style={styles.textInput}
        />
        <TextInputForm
          name="cpfcgc"
          mode="flat"
          keyboardType="number-pad"
          control={control}
          error={errors.cpfcgc}
          textContentType="none"
          maxLength={tipoPessoa == "F" ? 11 : 14}
          label={tipoPessoa == "F" ? "CPF - Somente números" : "CNPJ - Somente números"}
          style={styles.textInput}
        />
        {tipoPessoa == "F" ? <TextRgCnh /> : null}
        <TextInputForm
          name="cep"
          mode="flat"
          control={control}
          error={errors.cep}
          label="CEP - Somente números"
          style={styles.textInput}
          onBlur={(e) => {
            pesquisacep(e.target.value);
          }}
        />
        <PaperSelect
        disabled={disableEstado}
          label="Estado"
          textInputStyle={{ 
            marginLeft: '10px', 
            marginRight: '10px', 
            marginTop: '15px', 
            backgroundColor: 'white', 
            fontSize: 12 
          }}
          value={estado.value}
          onSelection={(value: any) => {
            setEstados({
              ...estado,
              value: value.text,
              selectedList: value.selectedList,
              error: '',
            });
          }}
          arrayList={[...estado.list]}
          selectedArrayList={estado.selectedList}
          multiEnable={false}
          textInputMode="flat"
          searchStyle={{ color: 'silver' }}
          dialogCloseButtonText="Voltar"
          dialogDoneButtonText="Confirmar"
        />
        <TextInputForm
          name="cidade"
          mode="flat"
          control={control}
          error={errors.cidade}
          textContentType="addressCity"
          label="Cidade"
          style={styles.textInput}
        />
        <TextInputForm
          name="endereco"
          mode="flat"
          control={control}
          error={errors.endereco}
          label="Endereço"
          style={styles.textInput}
        />
        <TextInputForm
          name="bairro"
          mode="flat"
          control={control}
          error={errors.bairro}
          label="Bairro"
          style={styles.textInput}
        />
        <Typography variant="h6" margin='10px'>CONTATO</Typography>
        <TextInputForm
          name="telcom"
          mode="flat"
          keyboardType="number-pad"
          control={control}
          error={errors.telcom}
          textContentType="telephoneNumber"
          label="Fone Comercial"
          style={styles.textInput}
        />
        <TextInputForm
          name="telres"
          mode="flat"
          keyboardType="number-pad"
          control={control}
          error={errors.telres}
          textContentType="telephoneNumber"
          label="Fone Residêncial"
          style={styles.textInput}
        />
        <TextInputForm
          name="cel1"
          mode="flat"
          keyboardType="number-pad"
          control={control}
          error={errors.cel1}
          textContentType="telephoneNumber"
          label="Celular 1"
          style={styles.textInput}
        />
        <TextInputForm
          name="cel2"
          mode="flat"
          keyboardType="number-pad"
          control={control}
          error={errors.cel1}
          textContentType="telephoneNumber"
          label="Celular 2"
          style={styles.textInput}
        />
        <Typography variant="h6" margin='10px'>REFERENCIAS BANCÁRIAS</Typography>
        <TextInputForm
          name="banco"
          mode="flat"
          control={control}
          error={errors.banco}
          label="Banco"
          style={styles.textInput}
        />
        <TextInputForm
          name="agencia"
          mode="flat"
          keyboardType="number-pad"
          control={control}
          error={errors.agencia}
          label="Agencia"
          style={styles.textInput}
        />
        <TextInputForm
          name="conta"
          mode="flat"
          keyboardType="number-pad"
          control={control}
          error={errors.conta}
          label="Conta"
          style={styles.textInput}
        />
        <Typography variant="h6" margin='10px'>PRODUTOR</Typography>
        <TextInputForm
          name="propri"
          mode="flat"
          control={control}
          error={errors.propri}
          label="Estabelecimento"
          style={styles.textInput}
        />
        <TextInputForm
          name="gta"
          mode="flat"
          keyboardType="number-pad"
          control={control}
          error={errors.gta}
          label="GTA"
          style={styles.textInput}
        />
        <TextInputForm
          name="endpro"
          mode="flat"
          control={control}
          error={errors.endpro}
          label="Endereço da Propriedade"
          style={styles.textInput}
        />
        <Typography variant="h6" margin='10px'>DADOS PROFISSIONAIS</Typography>
        <TextInputForm
          name="profiss"
          mode="flat"
          control={control}
          error={errors.profiss}
          label="Profissão"
          style={styles.textInput}
        />
        <TextInputForm
          name="empres"
          mode="flat"
          control={control}
          error={errors.empres}
          label="Empresa"
          style={styles.textInput}
        />
        <TextInputForm
          name="renda"
          mode="flat"
          control={control}
          error={errors.renda}
          label="Renda"
          style={styles.textInput}
        />
        <Typography variant="h6" margin='10px'>REFERENCIAS</Typography>
        <TextInputForm
          name="refer1"
          mode="flat"
          keyboardType="none"
          control={control}
          error={errors.refer1}
          label="Referencia"
          style={styles.textInput}
        />
        <TextInputForm
          name="telrefer1"
          mode="flat"
          keyboardType="none"
          control={control}
          error={errors.telrefer1}
          label="Contato Referencia"
          style={styles.textInput}
        />
        <Typography variant="h6" margin='10px'>SEGURANÇA</Typography>
        <TextInputForm
          name="senha"
          mode="flat"
          control={control}
          error={errors.senha}
          textContentType="password"
          secureTextEntry={true}
          label="Senha"
          style={styles.textInput}
        />
        <div style={{ margin: '15px' }}>
          <Typography variant="h6">Documento com Foto</Typography>
          <input
            type="file"
            onChange={handleFileDocumentoChange} />
          <Typography variant="h6">Comprovante de Renda</Typography>
          <input
            type="file" onChange={handleFileRendaChange} />
          <Typography variant="h6">Comprovante de Residência</Typography>
          <input
            type="file" onChange={handleFileResidenciaChange} />
        </div>
        <Button
          mode="contained"
          loading={loading}
          onPress={handleSubmit(handleUserRegister)}
          style={styles.buttonInput}
          buttonColor={theme.colors.tertiary}
        >
          Criar Conta
        </Button>
        <Typography
          align="center"
          fontSize='15'
          color={theme.colors.secondary}
        >
          {info}
        </Typography>
        <AlertModal
          visible={modalVisible}
          contact={false}
          message="Olá, seu cadastro já existe em nossa base de clientes.Entre em contato com nossa equipe: (53)99906-5590 ou (53)99961-6345."
          onClose={closeModal}
        />   
        <AlertModal
          visible={modalVisibleSucesso}
          contact={false}
          message="Conta criada com sucesso"
          onClose={closeModalSucesso}
        />   

      </ScrollView>
      <HeaderBotton></HeaderBotton>
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 5,
    paddingHorizontal: 0,
    flex: 1,
    // justifyContent: "center",
  },
  textInput: { fontSize: 12, marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: -10, backgroundColor: 'white' },
  buttonInput: { marginLeft: 100, marginRight: 100, marginTop: 15, marginBottom: 20 },
  error: {
    color: "red",
    fontSize: 16,
    fontWeight: 'bold'
  },
});
