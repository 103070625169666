import { Grid, IconButton, Typography } from '@mui/material';
import WhatsApp from '@mui/icons-material/WhatsApp';
import Phone from '@mui/icons-material/Phone';
import Location from '@mui/icons-material/LocationOn';
import { Linking } from "react-native";
import { View } from 'react-native';
import { theme } from '../../Global/Theme';

export default function CardHeaderBottom(props) {   
    
    const renderIcon = () => {
        switch (props.type) {
          case 'phone':
            return <Phone style={{ color: theme.colors.secondary, margin: 5 }} />
          case 'whatsapp':
            return <WhatsApp
                    style={{ color: theme.colors.secondary, margin: 5 }}
                    onClick={() => {                                 
                    Linking.openURL('https://wa.me/55' + props.contatoTratado);
                    }}                                                    
                    />
          case 'google-maps':
            return  <Location style={{ color: theme.colors.secondary, margin: 5 }} />
          default:
            return null;
        }
    };

    return (
        <View style={{ height: '35px', marginTop: '5px'}}>
        <Grid>
            <Grid item container>            
                <Grid>         
                    {renderIcon()}
                </Grid>                                      
                <Grid>                            
                    <Typography 
                        style={{ 
                            color: theme.colors.secondary, 
                            fontSize: props.isMobile ? 10 : 13, 
                            textAlign: 'start' }}
                    >
                        {props.nome}
                    </Typography>                 
                    <Typography 
                        style={{ 
                            color: theme.colors.secondary, 
                            fontSize: props.isMobile ? 10 : 13, 
                            textAlign: 'start' 
                        }}>
                            {props.contato}
                    </Typography>                 
                </Grid>                                      
            </Grid>                                      
        </Grid>
        </View>
    )
}