import React from "react";
import { Button, Typography } from "@mui/material";
import { Image, Linking, View } from "react-native";

const urlLogo = 'https://i.ibb.co/NsPhG1W/logo-macedo.png';

export default function AppsScreen() {    
  return (
    <View style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'linear-gradient(to bottom, #a98722, #fec727)',
    }}>
      <Image 
        style={{                 
          width: '250px', 
          height: '250px', 
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '20px',          
        }}             
        source={
          {
            uri: urlLogo,
          }
        }
      />   
      <Typography 
        style={{ 
          marginLeft: 'auto',
          marginRight: 'auto',
          fontFamily: 'Lato, sans-serif', 
          color: 'white', 
          fontWeight: 'bold', 
          textAlign: 'center',         
        }}
        fontSize={30}
      >
        O LUGAR DE BONS NEGÓCIOS
      </Typography>                   
      <Typography 
        style={{ 
          marginLeft: 'auto',
          marginRight: 'auto',
          fontFamily: 'Lato, sans-serif', 
          color: 'white',   
          textAlign: 'center',                  
        }}
        
      >
        Escolha abaixo a plafatorma de seu smartphone e aproveite os melhores leilões
      </Typography>                         
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
      <Button onClick={() => Linking.openURL('https://apps.apple.com/br/app/macedo-leil%C3%B5es-rurais/id1557897528')}>        
        <Image
          source={require('../../assets/image/app-store.png')}
          style={{
            width: innerWidth < 640 ? '150px' : '300px',
            height: innerWidth < 640 ? '40px': '80px',
            margin: '20px',
            borderRadius: 12,
          }}        
        />
      </Button>
      <Button onClick={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.FRVSoftware.MacedoLR&hl=pt_BR')}>        
        <Image
          source={require('../../assets/image/google-play.png')}
          style={{
            width: innerWidth < 640 ? '150px' : '300px',
            height: innerWidth < 640 ? '40px': '80px',
            margin: '20px',
            borderRadius: 12,
          }}
        />
      </Button>
      </View>                
    </View>
  );
}
