
import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { loadImage } from '../../Global/funcoes';

const NotaVirtual = ({ leilao, lote, lance }) => {
  const generatePDF = async () => {
    try {
      const doc = new jsPDF();
      const img = await loadImage('https://i.ibb.co/NsPhG1W/logo-macedo.png');
      
      const margin = 5; 
      const boxWidth = 150; 
      const boxHeight = 32; 
      const rectX = margin + boxWidth + 5; 
      const rectY = margin + 5; 
      const randomNumber = Math.floor(10000 + Math.random() * 90000);

      const generateNota = (offsetY, randomNumber) => {
        doc.setLineWidth(0.5);
        doc.rect(margin, margin + offsetY, boxWidth, boxHeight); 
        doc.rect(rectX - 2, margin + offsetY, 46, boxHeight);     
        doc.rect(172, 40 + offsetY, 30, 15);

        doc.addImage(img, 'PNG', margin + 2, margin + 2 + offsetY, 30, 30); 

        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');  
        doc.text('O LUGAR DE BONS NEGÓCIOS', margin + 35, margin + 7 + offsetY); 
        
        doc.setFontSize(8);
        doc.setFont('helvetica', 'normal');
        doc.text('w w w . m a c e d o l e i l o e s r u r a i s . c o m . b r', margin + 35, margin + 11 + offsetY, { align: 'justify'}); // Website
        
        doc.addImage('https://i.ibb.co/B4sP3N3/3374f84310d608d74314b06797d29822.jpg', 'jpg', margin + 108, margin + 8 + offsetY, 5, 5);
        doc.setFontSize(10);    
        doc.text('(53)', margin + 116, margin + 12 + offsetY); 
        doc.setFontSize(12);    
        doc.setFont('helvetica', 'bold');  
        doc.text('3035-1977', margin + 123, margin + 12 + offsetY); 
        doc.setFontSize(10);
        doc.text('Rua Jaguarão, 542 - Cassino - CEP: 96205-060 - Rio Grande/RS', margin + 36, margin + 20 + offsetY);       
        doc.line(margin + 35, margin + 22 + offsetY, margin + 143, margin + 22 + offsetY);
        doc.text('CNPJ: 18.371.003/0001-05', margin + 65, margin + 27 + offsetY); 
        
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('NOTA DE LEILÃO', rectX + 3, margin + 5 + offsetY); 
        
        doc.setFontSize(20);
        doc.text('%', rectX + 3, margin + 17 + offsetY); 
        
        doc.text('N: ' + randomNumber, rectX + 10, margin + 17 + offsetY); 
        
        doc.setFontSize(7);
        doc.setFont('helvetica', 'normal');
        doc.text('CONTRATO DE COMPRA E VENDA', rectX, rectY + 21 + offsetY); 
        doc.text('COM RESERVA DE DOMÍNIO', rectX + 3, rectY + 25 + offsetY); 
        
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');
        doc.text('LEILÃO: ', margin, margin + 40 + offsetY);
        doc.setFont('helvetica', 'normal');
        doc.text(leilao.LEILAO || '', margin + 25, margin + 40 + offsetY);
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(15);    
        doc.text('LOTE', 180, margin + 40 + offsetY);
        doc.setFontSize(20);    
        doc.text(lote.LOTEXX || '', 183, margin + 48 + offsetY);
        
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');
        doc.text('LEILOEIRO: ', margin, margin + 45 + offsetY);
        doc.setFont('helvetica', 'normal');
        doc.text(leilao.LEILOE || '', margin + 25, margin + 45 + offsetY);

        doc.setFont('helvetica', 'bold');
        doc.text('LOCAL: ', margin, margin + 50 + offsetY);
        doc.setFont('helvetica', 'normal');
        doc.text(leilao.ENDERE || '', margin + 25, margin + 50 + offsetY);

        doc.setFont('helvetica', 'bold');
        doc.text('DATA: ', 130, margin + 50 + offsetY);
        doc.setFont('helvetica', 'normal');
        doc.text(leilao.DATLEI || '', 145, margin + 50 + offsetY);

        doc.setFont('helvetica', 'bold');
        doc.text('VENDEDOR: ', margin, margin + 55 + offsetY);
        doc.setFont('helvetica', 'normal');
        doc.text(lote.NOMEXX, margin + 25, margin + 55 + offsetY);
        doc.setFont('helvetica', 'bold');
        doc.text('CIDADE: ', 130, margin + 55 + offsetY);
        doc.setFont('helvetica', 'normal');
        doc.text(lote.NOMECIDADE + '/'+ lote.NOMEESTADO, 145, margin + 55 + offsetY);

        doc.setFont('helvetica', 'bold');
        doc.text('COMPRADOR: ', margin, margin + 60 + offsetY);
        doc.setFont('helvetica', 'normal');
        doc.text('', margin + 25, margin + 60 + offsetY);
        doc.setFont('helvetica', 'bold');
        doc.text('CIDADE: ', 130, margin + 60 + offsetY);
        doc.setFont('helvetica', 'normal');
        doc.text('', 145, margin + 60 + offsetY);
        
        doc.autoTable({
          startY: margin + 63 + offsetY,
          startX: 0,
          head: [['QTD.', 'ANIMAL']],
          body: [['1', lote.DESLOT || '']],
          theme: 'plain',
          styles: { fontSize: 8, halign: 'left', valign: 'middle', lineWidth: 0.1 },
          columnStyles: {
            0: { cellWidth: 40 }, 
            1: { cellWidth: 145 }  
          }
        });
        doc.autoTable({
          startY: margin + 77 + offsetY,
          startX: 0,
          head: [['SBB/HBB', 'RP/TAT.', 'SEXO', 'NASC.', 'CATEGORIA']],
          body: [
          [lote.SBBXXX || '', lote.RPXXX || '', lote.CATEGO || '', lote.DATNAS || '', lote.DESCRICAO || '' ],      
          ],
          theme: 'plain',
          styles: { fontSize: 8, halign: 'left', valign: 'middle', lineWidth: 0.1 },
          columnStyles: {
            0: { cellWidth: 25 }, 
            1: { cellWidth: 25 }, 
            2: { cellWidth: 25 }, 
            3: { cellWidth: 25 }, 
            4: { cellWidth: 85 }  
          }
        });

        doc.setLineWidth(0.5);
        doc.rect(margin, margin + 92 + offsetY, 150, 38); 
        doc.rect(157, margin + 92 + offsetY, 47, 19); 
        doc.rect(157, margin + 111 + offsetY, 47, 19); 

        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');    
        doc.text('CONDIÇÕES DE PAGAMENTO', margin + 55, margin + 96 + offsetY);
        
        doc.setFontSize(9);
        doc.setFont('helvetica', 'normal');
        doc.text('VALOR LANCE', 160, margin + 96 + offsetY);
        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.text('', 160, margin + 104 + offsetY);

        doc.setFontSize(9);
        doc.setFont('helvetica', 'normal');
        doc.text('VALOR TOTAL', 160, margin + 114 + offsetY);
        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');

        const valorTotal = parseFloat(lance * 50);
        doc.text('', 160, margin + 122 + offsetY);
      };

      generateNota(0, randomNumber); // First copy
      generateNota(150, randomNumber); // Second copy

      const pdfOutput = doc.output('blob');
      const url = URL.createObjectURL(pdfOutput);
      window.open(url);    

    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <Button 
      onClick={generatePDF} 
      style={{
        backgroundColor: '#007BFF',
        color: '#fff',        
      }} 
      startIcon={<PrintIcon />}
    >
      Nota Virtual
    </Button>
  );
};

export default NotaVirtual;