import React from "react";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import Menu from "../../components/Gerais/Menu/Menu";
import { Typography, styled } from "@mui/material";
import Paper from '@mui/material/Paper';
import { theme } from "../../Global/Theme";
import { Appbar } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import { View } from "react-native";
import ArticleIcon from "@mui/icons-material/Article";

export default function PrivacyScreen() {
   
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <View style={{ flex: 1, backgroundColor: theme.colors.secondary, minHeight: '800px' }}>
      <Menu/>
      <Appbar
          style={{
            height: '70px',
            width: '100%',
            backgroundColor: theme.colors.secondary,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          >
          <ArticleIcon
          style={{
            color: theme.colors.tertiary,
            borderRadius: 50,   
            margin: 10         
          }}          
          />
          <Typography color={'black'}>PRIVACIDADE</Typography>
      </Appbar>          
      <ScrollView 
        showsHorizontalScrollIndicator={false} 
        contentContainerStyle={{
          margin: 15,
          backgroundColor: theme.colors.secondary,      
          alignSelf: "center"    
      }}>
        Política de Privacidade da Macedo Leilões Rurais
        <br />
        <br />        
        A Macedo Leilões Rurais respeita a sua privacidade e está comprometida com a proteção dos seus dados pessoais. Esta Política de Privacidade descreve como coletamos, usamos, divulgamos e protegemos suas informações quando você utiliza nossos serviços.
        <br />
        <br />        
        1. Coleta de Informações
        <br />
        <br />        
        1.1. Informações Pessoais:
        <br />
        Coletamos informações pessoais que você nos fornece diretamente, como nome, CPF, endereço, telefone, e-mail e dados bancários, quando você se registra em nosso site, participa de leilões, ou entra em contato conosco.
        <br />
        <br />                
        1.2. Informações de Uso:
        <br />
        Coletamos informações sobre como você utiliza nosso site, incluindo seu endereço IP, tipo de navegador, páginas visitadas, e o tempo gasto em cada página. Utilizamos cookies e tecnologias semelhantes para coletar essas informações.
        <br />
        <br />                
        2. Uso das Informações
        <br />
        <br />              
        2.1. Prestação de Serviços:
        <br />
        Utilizamos suas informações pessoais para fornecer e gerenciar nossos serviços, como permitir sua participação em leilões, processar pagamentos e enviar notificações relacionadas aos nossos serviços.
        <br />
        <br />                
        2.2. Comunicação:
        <br />
        Podemos usar suas informações de contato para enviar comunicações importantes, como confirmações de inscrição, atualizações de leilões, e informações sobre mudanças em nossos termos e políticas.
        <br />
        <br />                
        2.3. Melhoria de Serviços:
        <br />
        As informações de uso são analisadas para melhorar a funcionalidade do nosso site, personalizar sua experiência e desenvolver novos serviços.
        <br />
        <br />                
        3. Compartilhamento de Informações
        <br />
        <br />                
        3.1. Prestadores de Serviços:
        <br />
        Podemos compartilhar suas informações pessoais com terceiros que prestam serviços em nosso nome, como processadores de pagamento, serviços de hospedagem de sites e provedores de análise de dados. Esses terceiros estão obrigados a proteger suas informações e usá-las apenas para os fins especificados por nós.
        <br />
        <br />                
        3.2. Conformidade Legal:
        <br />
        Podemos divulgar suas informações pessoais se exigido por lei ou em resposta a processos legais, como ordens judiciais ou solicitações governamentais.
        <br />
        <br />                
        3.3. Proteção de Direitos:
        <br />
        Podemos divulgar suas informações pessoais para proteger nossos direitos, privacidade, segurança ou propriedade, e/ou de nossos clientes e outros, incluindo a aplicação de nossos Termos de Serviço.
        <br />
        <br />                
        4. Segurança das Informações
        <br />
        Implementamos medidas de segurança técnicas e organizacionais para proteger suas informações pessoais contra acesso, uso, alteração e divulgação não autorizados. No entanto, nenhum sistema de segurança é infalível e não podemos garantir a segurança absoluta das suas informações.
        <br />
        <br />                
        5. Seus Direitos
        <br />
        <br />
        5.1. Exclusão:
        <br />
        Você pode solicitar a exclusão de suas informações pessoais, sujeito a certas exceções, como o cumprimento de obrigações legais.
        <br />
        <br />
        5.2. Opção de Marketing:
        <br />
        Você pode optar por não receber comunicações de marketing a qualquer momento, seguindo as instruções de cancelamento fornecidas nas comunicações ou entrando em contato conosco diretamente.
        <br />
        <br />
        6. Alterações a esta Política
        <br />
        Podemos atualizar esta Política de Privacidade periodicamente. Notificaremos você sobre alterações significativas publicando a nova política em nosso site e/ou enviando uma notificação por e-mail.
        <br />
        <br />
        7. Contato
        <br />
        Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade ou sobre nossas práticas de privacidade, entre em contato conosco:
        <br />
        <br />
        Macedo Leilões Rurais
        <br />
        Telefone: (53) 3035.1977
        <br />
        E-mail: contato@macedoleiloesrurais.com.br
        <br />
        <br />
        Data de Vigência: Indeterminada
        <br />        
        Ao utilizar nossos serviços, você concorda com os termos desta Política de Privacidade. Agradecemos por confiar na Macedo Leilões Rurais e estamos à disposição para esclarecer qualquer dúvida que possa ter.      
      </ScrollView>
      <HeaderBotton></HeaderBotton>        
    </View>
  );
}
