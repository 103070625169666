import React from 'react';
import { View, Text, Button } from 'react-native';
import { Checkbox } from '@mui/material';
import jsPDF from 'jspdf';

const OrdemPrint = ({ lotes, totalFaturamento, medias, isTotalsVisible, setIsTotalsVisible }) => {
  const generatePDF = () => {
    const doc = new jsPDF();

    // Adiciona logo
    doc.addImage('https://i.ibb.co/NsPhG1W/logo-macedo.png', 'PNG', 10, 10, 30, 30);
    doc.setFontSize(20);
    doc.text('Ordem de Entrada', 120, 20, { align: 'center' });

    const startY = 45; // Posição inicial Y para os itens
    const boxHeight = 6; // Altura de cada box

    // Cabeçalho da tabela
    doc.setFontSize(7);
    doc.setFont('bold');
    doc.text('Ord.', 6, startY);
    doc.text('Lote', 13, startY);
    doc.text('Descrição', 20, startY);
    doc.text('Categoria', 110, startY);
    doc.text('Vendedor', 140, startY);

    const headerY = startY + 5;

    lotes.forEach((item, index) => {
      const currentY = headerY + index * boxHeight;

      // Alternar cores de fundo
      doc.setFillColor(index % 2 === 0 ? 'white' : 'silver');
      doc.rect(5, currentY, 200, boxHeight, 'F'); // Preencher o box

      // Adicionar texto dentro do box
      doc.setFontSize(7);
      doc.setTextColor(0);
      doc.setFont('normal');
      doc.text((index + 1).toString(), 7, currentY + 4); // ID como string

      doc.text(item.LOTEXX, 13, currentY + 4); // lote

      // Quebrar linha para DESLOT
      const maxDeslotWidth = 85; // Define a largura máxima para DESLOT
      const deslotLines = doc.splitTextToSize(item.DESLOT, maxDeslotWidth);

      deslotLines.forEach((line, i) => {
        doc.text(line, 20, currentY + 4 + (i * 4)); // Adiciona cada linha de DESLOT
      });

      // Tratar quebras para DESCRICAO
      const maxDescriptionWidth = 30; // Largura máxima para a descrição
      const descriptionLines = doc.splitTextToSize(item.DESCRICAO, maxDescriptionWidth);

      // Posicionar DESCRICAO abaixo de DESLOT
      descriptionLines.forEach((line, i) => {
        doc.text(line, 110, currentY + 4 + (i * 4)); // Ajuste na posição Y
      });

      // Categoria
      doc.text(item.NOMEXX, 140, currentY + 4); // Vendedor
    });

    let totalY = headerY + lotes.length * boxHeight + 10; // Posição para total
    doc.setFontSize(12);
    if (isTotalsVisible) {
      doc.text(`Total de Faturamento: R$ ${totalFaturamento.toFixed(2)}`, 10, totalY);

      let mediaY = totalY + 5;
      medias.forEach((media) => {
        doc.setFontSize(10);
        doc.text(`Média ${media.RACAS}: Quantidade: ${media.QTD} - R$ ${media.TOTGERAL.toFixed(2)}`, 10, mediaY);
        mediaY += 5;
      });
    }

    // Criar um blob do PDF e abrir em nova aba
    const pdfOutput = doc.output('blob');
    const url = URL.createObjectURL(pdfOutput);
    window.open(url);
  };

  return (
    <View style={{ padding: 16 }}>
      <Checkbox
        checked={isTotalsVisible}
        onChange={() => setIsTotalsVisible(!isTotalsVisible)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <Text>Mostrar Totais na Ordem</Text>
      <Button title="Imprimir Ordem de Entrada" onPress={generatePDF} />
    </View>
  );
};

export default OrdemPrint;
