import React from "react";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import Menu from "../../components/Gerais/Menu/Menu";
import { CardMedia, Grid, Typography, styled } from "@mui/material";
import Paper from '@mui/material/Paper';
import { theme } from "../../Global/Theme";
import { Appbar, IconButton } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import { View, Image } from "react-native";
import ArticleIcon from "@mui/icons-material/Article";

export default function PartnersScreen() {
   
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <View style={{ flex: 1, backgroundColor: theme.colors.secondary, minHeight: '800px' }}>
      <Menu/>
      <Appbar
          style={{
            height: '70px',
            width: '100%',
            backgroundColor: theme.colors.secondary,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          >
          <ArticleIcon
          style={{
            color: theme.colors.tertiary,
            borderRadius: 50,   
            margin: 10         
          }}          
          />        
          <Typography color={'black'}>PARCEIROS</Typography>
      </Appbar>          
      <ScrollView 
        showsHorizontalScrollIndicator={false} 
        contentContainerStyle={{
          margin: 15,
          backgroundColor: theme.colors.secondary,      
          alignSelf: "center"    
      }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item>
          <Item>
          <Image
              resizeMode="contain"
              style={{ width: 300, height: 200 }}                               
              source={{ uri: "https://i.ibb.co/10rjCS5/unicontrol.jpg" }}
          />                                              
          </Item>
        </Grid>
        <Grid item>
          <Item>
          <Image
              resizeMode="contain"
              style={{ width: 200, height: 200 }}                               
              source={{ uri: "https://i.ibb.co/z5qNRPL/dom-joaquin.jpg" }}
          />                                              
          
          </Item>
        </Grid>     
      </Grid>

      </ScrollView>
      <HeaderBotton></HeaderBotton>        
    </View>
  );
}
