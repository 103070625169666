import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Modal, FlatList } from 'react-native';
import { getLeiloes } from '../../Services/api/AuthServices';

const AuctionSelector = ({ onLeilaoSelect }) => {
  const [leiloes, setLeiloes] = useState([]);
  const [selectedLeilao, setSelectedLeilao] = useState(null);  
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const fetchLeiloes = async () => {
      try {
        const data = await getLeiloes();
        setLeiloes(data);
      } catch (error) {
        console.error('Error fetching leilões:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeiloes();
  }, []);

  const handleLeilaoSelect = (item) => {
    setSelectedLeilao(item);
    onLeilaoSelect(item);    
    setModalVisible(false);
  };

  const renderItem = ({ item }) => (
    <TouchableOpacity onPress={() => handleLeilaoSelect(item)} style={styles.item}>
      <Text style={styles.itemText}>{item.LEILAO}</Text>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      {loading ? (
        <Text>Carregando leilões...</Text>
      ) : (
        <>
          <Text style={styles.label}>Selecione um Leilão:</Text>
          <TouchableOpacity onPress={() => setModalVisible(true)} style={styles.picker}>
            <Text style={styles.selectedText}>
              {selectedLeilao ? selectedLeilao.LEILAO : 'Selecione um leilão'}
            </Text>
          </TouchableOpacity>

          <Modal visible={modalVisible} transparent animationType="slide">
            <View style={styles.modalContainer}>
              <View style={styles.modalContent}>
                <FlatList
                  data={leiloes}
                  renderItem={renderItem}
                  keyExtractor={(item) => item.ID.toString()}
                />
                <TouchableOpacity onPress={() => setModalVisible(false)} style={styles.closeButton}>
                  <Text style={styles.closeButtonText}>Fechar</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  label: {
    fontSize: 16,
    marginBottom: 10,
    fontWeight: 'bold',
    color: '#333',
  },
  picker: {
    height: 50,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 8,
    justifyContent: 'center',
    paddingHorizontal: 10,
    backgroundColor: '#f9f9f9',
  },
  selectedText: {
    fontSize: 16,
    color: '#333',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: '80%',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    elevation: 5,
  },
  item: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  itemText: {
    fontSize: 16,
    color: '#333',
  },
  closeButton: {
    padding: 15,
    backgroundColor: '#007BFF',
    borderRadius: 8,
    marginTop: 20,
    alignItems: 'center',
  },
  closeButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
});

export default AuctionSelector;
