import React, {
  useState,
  useEffect,
  ReactNode,
  createContext,
  FunctionComponent,
} from "react";
import { getUser } from "../../Services/api/AuthServices";
import { getKey } from "../../Services/SecureStore";

export type AuthTypes = {
  token: string;
  loggedIn: boolean;
  user: {
    id: number;
    nomexx: string;
    senhax: string;
    cpfxxx: string;
    cnpjxx: any;
    emailx: string;
  };
};
type PropsAuthContext = {
  state: AuthTypes;
  setState: React.Dispatch<React.SetStateAction<AuthTypes>>;
  logout: () => void;
  login: () => void;
};

export const DEFAULT_VALUE = {
  state: {
    token: "",
    loggedIn: false,
    user: {
      id: 0,
      nomexx: "",
      senhax: "",
      cpfxxx: "",
      cnpjxx: "",
      emailx: "",
    },
  },
  setState: () => {},
  logout: () => {},
  login: () => {},
};

interface BaseLayoutProps {
  children?: ReactNode;
}
const AuthContext = createContext<PropsAuthContext>(DEFAULT_VALUE);

const AuthContextProvider: FunctionComponent<BaseLayoutProps> = ({
  children,
}) => {
  const [state, setState] = useState(DEFAULT_VALUE.state);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const user = await getKey("user");

        if (!user) {
          setState({ ...state, loggedIn: false });
          return;
        }
        // const serializedUser = JSON.parse(user);
        // const refreshUser = await getUser(serializedUser?.id);
        // console.log("refreshUser", refreshUser);
        // const data = await getUserProfile();
        // const data = {
        //   id: 4905,
        //   nomexx: "FELIPE ROSLER VINHAS",
        //   senhax: "81227330",
        //   cpfxxx: "020.235.770-80",
        //   cnpjxx: null,
        //   emailx: "felipe.vinhas@hotmail.com",
        // };
        //console.log("Iniciar App", user.cpfxxx);
        setState({ ...state, loggedIn: true, user: user });
        setUserData(user);
      } catch (err) {
        setState({ ...state, loggedIn: false });
      }
    })();
  }, []);

  const login = async () => {
    try {
    } catch (err) {
      console.log("error logging in..", err);
    }
  };

  const logout = async () => {
    try {
    } catch (err) {
      console.log("error logging out..", err);
    }
  };

  return (
    <AuthContext.Provider value={{ state, setState, logout, login }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
function getUserProfile() {
  throw new Error("Function not implemented.");
}
