import React from 'react';
import { Text } from 'react-native-paper';

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
      <Text>{value} {type}</Text>    
  );
};

export default DateTimeDisplay;
