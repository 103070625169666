import emailjs from 'emailjs-com';

let templateID = '';
const serviceID = 'service_oheoyng';
  
export function sendEmail(email, nome, mensangem, recuperacao, new_token : string) {    
  if (recuperacao === "recuperacao"){
    templateID = 'template_a08tiob';
  } else {
    templateID = 'template_qtf5k6i';
  }


    var templateParams = {
      to_email: email,
      to_name: nome,
      message: mensangem,
      token: new_token 
  };
  
    emailjs.send(      
      serviceID,
      templateID,
      templateParams,
      'tczgEwOhyOisEWkpP'
    )
    .then((result) => {
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
  });  
}
