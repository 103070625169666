import React from "react";
import { Controller } from "react-hook-form";
import { TextInput, HelperText } from "react-native-paper";

export default function TextInputForm({ control, name, error, ...rest }) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextInput onChangeText={onChange} value={value} {...rest} />
        )}
      />
      {error ? (
        <HelperText type="error" visible={error}>
          {error.message}
        </HelperText>
      ) : (
        <HelperText type="error" visible={true}>
          {""}
        </HelperText>
      )}
    </>
  );
}
