import React, { useState } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet, TextInput } from 'react-native';
import { LoadingButton } from '@mui/lab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { theme } from '../Global/Theme';

const getColorById = (id) => {
  const colors = [theme.colors.primary];
  return colors[id % colors.length];
};

const DraggableItem = ({ item, index, moveUp, moveDown, onIniciarVenda }) => {
  const [manualIndex, setManualIndex] = useState(index + 1); // Inicializar o estado com o índice atual

  const handleIndexChange = (text) => {
    const newIndex = parseInt(text);
    if (!isNaN(newIndex) && newIndex > 0 && newIndex <= item.length) {
      setManualIndex(newIndex);
      // Aqui você pode adicionar lógica para mover o item para o novo índice
    }
  };

  return (
    <View style={[styles.item, { backgroundColor: getColorById(item.ID) }]}>
      <Image source={{ uri: `https://macedo.s3.us-east-2.amazonaws.com/lote_1_img_${item.ID}.jpg` }} style={styles.image} />
      <View style={styles.itemContent}>
        <TextInput
          style={styles.indexInput}
          value={manualIndex.toString()}
          onChangeText={handleIndexChange}
          keyboardType="numeric"
        />
        <Text style={styles.itemText}>Lote: {item.LOTEXX}</Text>
        <Text style={styles.itemText}>Descrição: {item.DESLOT}</Text>
        <Text style={styles.itemText}>Pelagem: {item.PELAGE}</Text>
        {item.ESPECIES === 'EQUINOS' && (       
           <Text style={styles.itemText}>RP: {item.RPXXX} - SBB: {item.SBBXXX}</Text>
        )}
        {item.ESPECIES === 'BOVINOS' && (       
          <>
           <Text style={styles.itemText}>Tatuagem: {item.RPXXX} - HBB: {item.SBBXXX}</Text>
           <Text style={styles.itemText}>Peso: {item.PESOXX}</Text>
           </>
        )}

        {item.CATEGO === "M" && <Text style={styles.itemText}>Categoria: MACHO</Text>}
        {item.CATEGO === "F" && <Text style={styles.itemText}>Categoria: FEMEA</Text>}
        {item.CATEGO === "C" && <Text style={styles.itemText}>Categoria: CASTRADO</Text>}
        {item.CATEGO === "S" && <Text style={styles.itemText}>Categoria: COBERTURA</Text>}
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity onPress={() => moveUp(index)} disabled={index === 0}>
          <LoadingButton                    
            loadingPosition="start"
            style={styles.button}
            startIcon={<ArrowUpwardIcon />}
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => moveDown(index)} disabled={index === item.length - 1}>
          <LoadingButton                    
            loadingPosition="start"
            style={styles.button}
            startIcon={<ArrowDownwardIcon />}
          />
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={onIniciarVenda}>
          <Text style={styles.buttonText}>Iniciar Venda</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    padding: 20,
    marginVertical: 10,
    borderRadius: 8,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  image: {
    width: 80,
    height: 80,
    marginRight: 10,
    borderRadius: 10,
  },
  itemContent: {
    flex: 1,
  },
  itemText: {
    color: '#fff',
  },
  indexInput: {
    width: 50,
    height: 30,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    color: '#fff',
    marginBottom: 10,
    textAlign: 'center',
  },
  buttonContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    width: '95%',
    margin: 10,
    borderColor: theme.colors.tertiary,
    borderRadius: 5,
    color: 'white',
    backgroundColor: theme.colors.primary,
  },
  buttonText: {
    color: 'white',
  },
});

export default DraggableItem;
