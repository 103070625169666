import React from "react";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import Menu from "../../components/Gerais/Menu/Menu";
import { Grid, Typography } from "@mui/material";
import { theme } from "../../Global/Theme";
import { Appbar, Card } from "react-native-paper";
import { View } from "react-native";
import ArticleIcon from "@mui/icons-material/Article";

export default function ContactScreen() {   
  return (
    <View style={{ flex: 1, backgroundColor: theme.colors.secondary }}>
      <Menu/>
      <Appbar
          style={{
            height: '70px',
            width: '100%',
            backgroundColor: theme.colors.secondary,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          >
          <ArticleIcon
          style={{
            color: theme.colors.tertiary,
            borderRadius: 50,   
            margin: 10         
          }}          
          />
          <Typography color={'black'}>CONTATOS</Typography>
      </Appbar>          
      <View 
        style={{
          paddingBottom: 20,        
          backgroundColor: theme.colors.secondary,     
          margin: 'auto',     
          minHeight: '600px'
      }}>
      
        <Grid container spacing={2}>
          <Grid item container>            
              <Grid>                            
                <Card.Cover        
                  resizeMode="cover"
                  style={{                              
                    backgroundColor: theme.colors.secondary,
                    width: '300px',
                    height: '300px',
                    marginRight: 'auto',
                    marginLeft: 'auto',          
                    borderRadius: 12,

                  }}        
                  source={{
                    uri:
                      "https://i.ibb.co/9332nYh/img-contatos.png"
                  }}
                />                    
              </Grid>                                      
              <Grid>                            
                <Card.Cover        
                  resizeMode="cover"
                  style={{          
                    marginLeft: 10,          
                    backgroundColor: theme.colors.secondary,
                    width: '150px',
                    height: '170px',
                    borderRadius: 12,
                    margin: '10px'
                  }}        
                  source={{
                    uri:
                      "https://i.ibb.co/Qj8yGvL/btn-cont-santiago.png"
                  }}
                />                    
                  <Card.Cover        
                  resizeMode="cover"
                  style={{          
                    marginLeft: 10,          
                    backgroundColor: theme.colors.secondary,
                    width: '150px',
                    height: '170px',
                    borderRadius: 12,
                    margin: '10px'

                  }}        
                  source={{
                    uri:
                      "https://i.ibb.co/8NjFYMV/btn-cont-sole.png"
                  }}
                />                    
                <Card.Cover        
                  resizeMode="cover"
                  style={{          
                    marginLeft: 10,          
                    backgroundColor: theme.colors.secondary,
                    width: '150px',
                    height: '170px',
                    borderRadius: 12,
                    margin: '10px'
                  }}        
                  source={{
                    uri:
                      "https://i.ibb.co/fdYH1kh/btn-cont-thiago.png"
                  }}
                />
              </Grid>                                      
              <Grid>                            
                <Card.Cover        
                  resizeMode="cover"
                  style={{          
                    marginLeft: 10,          
                    backgroundColor: theme.colors.secondary,
                    width: '150px',
                    height: '170px',
                    borderRadius: 12,
                    margin: '10px'
                  }}        
                  source={{
                    uri:
                      "https://i.ibb.co/gtX4SP5/btn-cont-marcelo-fonseca.png"
                  }}
                />                    
                  <Card.Cover        
                  resizeMode="cover"
                  style={{          
                    marginLeft: 10,          
                    backgroundColor: theme.colors.secondary,
                    width: '150px',
                    height: '170px',
                    borderRadius: 12,
                    margin: '10px'
                  }}        
                  source={{
                    uri:
                      "https://i.ibb.co/JjwVsjz/btn-cont-mariana.png"
                  }}
                />                    
                  <Card.Cover        
                  resizeMode="cover"
                  style={{          
                    marginLeft: 10,          
                    backgroundColor: theme.colors.secondary,
                    width: '150px',
                    height: '170px',
                    borderRadius: 12,
                    margin: '10px'
                  }}        
                  source={{
                    uri:
                      "https://i.ibb.co/7tDxmKW/btn-cont-priscila.png"
                  }}
                />     
              </Grid>                                      
          </Grid>
          
        </Grid>                        
      </View>
      <HeaderBotton></HeaderBotton>        
    </View>
  );
}
