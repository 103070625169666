
import axiosInstance from "./axios";

const bodyCidade = {
  id: 0,
  cidade: "",
  estado: "",
  pais: ""
};

const bodySignup = {
  id: 0,
  codfat: 0,
  codcla: "",
  rg: "",
  datnas: "",
  cep: "",
  endereco: "",
  bairro: "",
  cidpro: 0,
  ceppro: "",
  endpro: "",
  baipro: "",
  caipro: "",
  telres: "",
  telcom: "",
  cel2: "",
  propri: "",
  pai: "",
  mae: "",
  obs: "CADASTRO ENVIADO VIA SITE",
  banco: "",
  agencia: "",
  conta: "",
  renda: 0,
  gta: "",
  profiss: "",
  empres: "",
  refer2: "",
  telrefere1: "",
  telrefere2: "",
};

export async function Signin(cpf: string, password: string) {
  try {
    //url encode    
    password = btoa(password);    
    const { data } = await axiosInstance.get(
      `Clientes/Login/${cpf}/${password}`
    );
    return data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function getUser(id: number) {
  try {
    const { data } = await axiosInstance.get(`Clientes/${id}`);    
    return data[0];
  } catch (error) {
    console.log("error", error);
  }
}

export async function signUp(body: any) {
  try {    
    const { data } = await axiosInstance.post(`Clientes`, {
      ...bodySignup,
      ...body,
    });    
    return data;
  } catch (error) {
    console.log("error signUp", error);
    throw new Error("Erro ao criar conta");
  }
}
export async function getCity(city) {
  try {
    const { data } = await axiosInstance.get(`Cidade/${city}`);
    return data;
  } catch (error) {
    console.log("error getCity", error);
    throw new Error("Erro ao consultar cidade");
  }
}

export async function setLance(lance: any) {
  try {
    const { data } = await axiosInstance.post("Lance", lance);
    return data;
  } catch (error) {
    console.log("error", error);
    throw new Error("Erro ao dar lance");
  }
}

export async function verificarUltimoLance(lote: string) {
  try {
    const { data } = await axiosInstance.get(
      `UltimoLance/${lote}`
    );
    return data;
  } catch (error) {
    console.log("error", error);
    throw new Error("Lote não encontrado");
  }
}

export async function checkDocument(document: string) {
  try {
    const { data } = await axiosInstance.get(
      `Clientes/RecuperarID/${document}`
    );
    return data;
  } catch (error) {
    console.log("error", error);
    throw new Error("Usuário não encontrado");
  }
}

export async function updatePassword(id: string, newPassword: string) {
  try {    
    const { data } = await axiosInstance.put(
      `Clientes/UpdateSenha/${id}/${newPassword}`
    );
    return data;
  } catch (error) {
    console.log("error updatePassword", error);
    throw new Error("Usuário não encontrado");
  }
}

export async function getLeilao(id: string) {
  try {
    const { data } = await axiosInstance.get(`/LeiloesLotes/${id}`);
    return data;
  } catch (error) {
    console.log("error ao obter leilão", error);
    throw new Error("error ao obter leilão");
  }
}

export async function getLeiloes() {
  try {
    const { data } = await axiosInstance.get(`/LeiloesLotes`);
    return data;
  } catch (error) {
    console.log("error ao obter leilão", error);
    throw new Error("error ao obter leilão");
  }
}

export async function getLeilaoID(id : string) {
  try {
    const { data } = await axiosInstance.get(`/Leiloes/${id}`);
    return data;
  } catch (error) {
    console.log("error ao obter leilão", error);
    throw new Error("error ao obter leilão");
  }
}

export async function getCategorias() {
  try {
    const { data } = await axiosInstance.get(`/Categoria/`);
    return data;
  } catch (error) {
    console.log("error ao obter Categoria", error);
    throw new Error("error ao obter Categoria");
  }
}

export async function getUltimoLancePorLote(idLote: string) {
  try {    
    const { data } = await axiosInstance.get(`/UltimoLanceLotes/${idLote}`);    
    return data;
  } catch (error) {
    console.log("error ao obter ultimo lance", error);
    throw new Error("error ao obter ultimo lance");
  }
}

export async function getLancePorLote(idleilao: string) {
  try {    
    const { data } = await axiosInstance.get(`/LotesLancesPorLeilao/${idleilao}`);    
    return data;
  } catch (error) {
    console.log("error ao obter ultimo lance", error);
    throw new Error("error ao obter ultimo lance");
  }
}

export async function getStatusCliente(id) {
  try {
    await axiosInstance.get(`/StatusCliente/${id}`);
    return true
  } catch  {
    return false
  }
}

export async function getClienteID(id) {
  try {
    const { data } = await axiosInstance.get(`/CadastroCliente/${id}`);
    return data;
  } catch (error) {
    console.log("error ao obter Cadastro Cliente", error);
    throw new Error("error ao obter Cadastro Cliente");
  }
}

export async function getClientes() {
  try {
    const { data } = await axiosInstance.get(`/Clientes`);
    return data;
  } catch (error) {
    console.log("error ao obter Cadastro Cliente", error);
    throw new Error("error ao obter Cadastro Cliente");
  }
}


export async function getLancesporCliente(id) {
  try {
    const { data } = await axiosInstance.get(`/Clientes/Lances/${id}`);
    return data;
  } catch (error) {
    console.log("error ao obter Lances", error);
    throw new Error("error ao obter Lances");
  }
}

export async function getEmailporDocumento(doc) {
  try {
    const { data } = await axiosInstance.get(`/Clientes/recuperarEMAIL/${doc}`);
    return data;
  } catch (error) {
    console.log("error ao obter Email", error);
    throw new Error("error ao obter Email");
  }
}

export async function setTokenRecovery(token: any) {
  try {
    const { data } = await axiosInstance.post("Token", token);
    return data;
  } catch (error) {
    console.log("error", error);
    throw new Error("Erro ao dar token");
  }
}

export async function getToken(token) {
  try {
    const { data } = await axiosInstance.get(`/Token/${token}`);
    return data;
  } catch (error) {
    console.log("error", error);
    throw new Error("Erro ao dar token");
  }
}

export async function getLotesparaOrdem(id) {
  try {
    const { data } = await axiosInstance.get(`/LotesPorLeilao/${id}`);
    return data;
  } catch (error) {
    console.log("error ao obter Lances", error);
    throw new Error("error ao obter Lances");
  }
}

export async function getMediasporLeilao(id) {
  try {
    const { data } = await axiosInstance.get(`/MediaPorLeilao/${id}`);
    return data;
  } catch (error) {
    console.log("error ao obter Lances", error);
    throw new Error("error ao obter Lances");
  }
}



