import { View } from "react-native";
import React from "react";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import Menu from "../../components/Gerais/Menu/Menu";
import { Typography } from "@mui/material";
import { theme } from "../../Global/Theme";
import { Appbar, IconButton } from "react-native-paper";
import ArticleIcon from "@mui/icons-material/Article";

export default function HistoryScreen() {
  return (
    <View>
    <Menu/>      
        <Appbar
          style={{
            height: '70px',
            width: '100%',
            backgroundColor: theme.colors.secondary,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
        <ArticleIcon
          style={{
            color: theme.colors.tertiary,
            borderRadius: 50,   
            margin: 10         
          }}          
        />
        <Typography color={theme.colors.primary}>EMPRESA</Typography>
        </Appbar>
        <Typography translate="no" style={{ margin: 20 }} color={theme.colors.primary}>
        Macedo, uma família 100% ligada ao Cavalo Crioulo, pecuária e agronegócio há mais de 60 anos.
        </Typography>                          
        <Typography translate="no" style={{ margin: 20 }} color={theme.colors.primary}>
        Luiz Carlos Macedo, pai do titular da empresa, Santiago Macedo, foi um dos idealizadores da maior prova equestre da América do Sul, o Freio de Ouro.
        </Typography>                          
        <Typography translate="no" style={{ margin: 20 }} color={theme.colors.primary}>
        Macedo Leilões é a continuação de um sonho de anos trabalhados no ramo do agronegócio.
        </Typography>                          
        <Typography translate="no" style={{ margin: 20 }} color={theme.colors.primary}>
        Agradecemos aos amigos e clientes pela confiança, nos colocando à disposição para realização de negócios de qualidade, em busca de melhores resultados e satisfação.
        </Typography>                          
        <Typography translate="no" style={{ margin: 20 }} color={theme.colors.primary}>      
        Macedo Leilões Rurais - Lugar de bons negócios!
        </Typography>                    
        <img style={{ width: '60%', maxWidth: '450px', margin: '10px'}} src="https://i.ibb.co/rKBrgQr/macedao.jpg"/>
        <HeaderBotton></HeaderBotton>
    </View>
  );
}
