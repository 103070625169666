import React, { useEffect, useState } from "react";
import { ScrollView } from "react-native-gesture-handler";
import { Checkbox, IconButton, PaperProvider } from "react-native-paper";
import axiosInstance from "../../Services/api/axios";
import LocalAtm from '@mui/icons-material/LocalAtm';
import { View } from "react-native";
import { Grid, Typography } from "@mui/material";
import { theme } from "../../Global/Theme";

export default function CardCotacoesHeaderBottom(props) {
  const [cotacoes, setCotacoes] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);

  useEffect(() => {
    setisLoading(true);
    axiosInstance
      .get("/Cotacao")
      .then((response) => {
        setCotacoes(response.data);
        setisLoading(false);
      })
      .catch((err) => {
        setisError(true);
        setisLoading(false);
      });
  }, []);



  return (
    <View style={{ height: '35px', marginTop: '5px' }}>
      {cotacoes.map((cotacao) => {
        return (
          <Grid>
            <Grid item container>
              <Grid>
                <LocalAtm
                  style={{ color: theme.colors.secondary, margin: 5 }}                                                                  
                />
              </Grid>
              <Grid>
                <Typography style={{ color: theme.colors.secondary, fontSize: props.isMobile ? 10 : 13, textAlign: 'start' }}>{cotacao.COTACAO}</Typography>
                <Typography style={{ color: theme.colors.secondary, fontSize: props.isMobile ? 10 : 13, textAlign: 'start' }}>{cotacao.TIPO + cotacao.VALOR}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )
      })}
    </View>
  )
}