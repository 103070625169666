import { Grid, Typography } from '@mui/material';
import React from 'react';
import { View } from 'react-native';
import { theme } from '../../Global/Theme';
import { useNavigation } from "@react-navigation/native";
import { useCookies } from 'react-cookie';
import { AuthContext } from '../../Context/AuthContext/AuthContext';
import LogoutIcon from '@mui/icons-material/Logout';

const CardLogado = (props) => (
    <View style={{ height: '35px', marginTop: '5px'}}>
    <Grid>
        <Grid item container>            
            <Grid>                                                       
            <LogoutIcon  
                style={{ color: theme.colors.secondary, margin: 5 }}                                                                                                                
                onClick={() => props.onPress()}                
            />
            </Grid>                                      
            <Grid>                            
                <Typography style={{ color: theme.colors.secondary, fontSize: props.isMobile ? 10 : 13, textAlign: 'start' }}>{props.nome}</Typography>                 
                <Typography style={{ color: theme.colors.secondary, fontSize: props.isMobile ? 10 : 13, textAlign: 'start' }}>{props.email}</Typography>                 
                <Typography style={{ color: theme.colors.secondary, fontSize: props.isMobile ? 10 : 13, textAlign: 'start' }}>{props.cpf}</Typography>                 
            </Grid>                                      
        </Grid>                                      
        </Grid>
    </View>
  );

  const CardLogout = (props) => (    
    <View style={{ height: '35px', marginTop: '5px'}}>
    <Grid>
        <Grid item container>            
            <Grid>                                            
            <LogoutIcon  
                style={{ color: theme.colors.secondary, margin: 5 }}                                                                                                                
                onClick={() => props.onPress()}                
            />
            </Grid>                                      
            <Grid>                            
                <Typography translate='no' style={{ color: theme.colors.secondary, fontSize: props.isMobile ? 10 : 12, textAlign: 'start' }}>FAÇA SEU LOGIN</Typography>                                 
                <Typography translate='no' style={{ color: theme.colors.secondary, fontSize: props.isMobile ? 10 : 12, textAlign: 'start' }}>EFETUE SEUS LANCES</Typography>                                 
            </Grid>                                      
        </Grid>                                      
        </Grid>
    </View>
  );

  export default function CardPerfilHeaderBottom(props) {      
    const navigation = useNavigation<any>();         
    const { setState, state } = React.useContext(AuthContext);
    const [cookie, setCookie, removeCookie] = useCookies();

    const handleLogout = () => {        
        removeCookie('macedo', { path: '/' });
        setState({ ...state, loggedIn: false });
        window.location.reload();
    }
 
    return props.id == 0 ? (<CardLogout isMobile={props.isMobile}/>) : (<CardLogado onPress={() => handleLogout()} isMobile={props.isMobile} nome={props.nome} email={props.email} cpf={props.cpf}/>)                          
}