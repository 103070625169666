import React from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet, Linking } from 'react-native';
import { theme } from '../../Global/Theme';
import WhatsApp from '@mui/icons-material/WhatsApp';
import { Button } from '@mui/material';

const AlertModal = ({ visible, message, contact, onClose }) => {
  return (
    <Modal
      transparent={true}
      animationType="slide"
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={styles.overlay}>
        <View style={styles.modalContainer}>
          <Text style={styles.message}>{message}</Text>
          {
            contact &&
            <View>
              <Button                
                style={{ 
                  marginBottom: 10,
                  backgroundColor: theme.colors.primary, 
                  color: 'white', 
                  width: '100%',
                  fontSize: 10
                }}
                startIcon={
                  <WhatsApp/>
                }                                            
                onClick={() => {                                 
                  Linking.openURL('https://wa.me/5553999616345');
                }}   
              >  
                Marcelo Solé - (53)99961-6345
              </Button>                 
              <Button             
                style={{ 
                  marginBottom: 10,
                  backgroundColor: theme.colors.primary, 
                  color: 'white', 
                  width: '100%',
                  fontSize: 10
                }}
                startIcon={
                  <WhatsApp/>
                }                                            
                onClick={() => {                                 
                  Linking.openURL('https://wa.me/5553999065590');
                }}     
              >                                               
                Marcelo Fonseca - (53)99906-5590
              </Button>              
            </View>
          }
          <TouchableOpacity onPress={onClose} style={styles.button}>
            <Text style={styles.buttonText}>Fechar</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContainer: {
    width: 300,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
  },
  message: {
    marginBottom: 20,
    fontSize: 16,
    textAlign: 'center',
  },
  button: {
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: theme.colors.tertiary,
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
  },
});

export default AlertModal;