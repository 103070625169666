import React, { useState } from 'react';
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import AuctionSelector from '../AuctionSelector/AuctionSelector';
import DraggableList from '../../components/DraggableList';

const OrderLotesScreen = () => {
  const [selectedLeilao, setSelectedLeilao] = useState([]);  
  const [loading, setLoading] = useState(false); 
  
  const handleLeilaoSelect = (item) => {    
    console.log(item)
    setSelectedLeilao(item);    
  };

  return (    
    <View style={styles.container}>
      <Text style={styles.title}>Organizar Lotes</Text>
      <AuctionSelector        
        onLeilaoSelect={handleLeilaoSelect} 
      />    
      {selectedLeilao ? (
        <View>
          <DraggableList leilao={selectedLeilao} />         
        </View>
      ) : (
        <View style={styles.messageContainer}>
          <Text style={styles.messageText}>Por favor, selecione um leilão.</Text>
        </View>
      )}
      
      {loading && <ActivityIndicator size="large" color="#007BFF" style={styles.loader} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#f8f9fa', // Cor de fundo clara
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 20,
    textAlign: 'center',
  },
  messageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageText: {
    fontSize: 18,
    color: '#666',
    textAlign: 'center',
    paddingHorizontal: 20,
  },
  loader: {
    marginTop: 20,
  },
});

export default OrderLotesScreen;
