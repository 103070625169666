import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { loadImage } from '../../Global/funcoes';

const NotaPrint = ({ leilao, lote, lance }) => {
  const generatePDF = async () => {
    try {
      const doc = new jsPDF();
      const img = await loadImage('https://i.ibb.co/NsPhG1W/logo-macedo.png');
    
      const margin = 5; 
      const boxWidth = 150; 
      const boxHeight = 32; 
      const rectX = margin + boxWidth + 5; 
      const rectY = margin + 5;       
          
      doc.setLineWidth(0.5);
      doc.rect(margin, margin, boxWidth, boxHeight); 
        
      doc.rect(rectX - 2, margin, 46, boxHeight);     
      doc.rect(172, 40, 30, 15);
    
      doc.addImage(img, 'PNG', margin + 2, margin + 2, 30, 30); 
    
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');  
      doc.text('O LUGAR DE BONS NEGÓCIOS', margin + 35, margin + 7); 
      
      doc.setFontSize(8);
      doc.setFont('helvetica', 'normal');
      doc.text('w w w . m a c e d o l e i l o e s r u r a i s . c o m . b r', margin + 35, margin + 11, { align: 'justify'}); // Website
      
      doc.addImage('https://i.ibb.co/B4sP3N3/3374f84310d608d74314b06797d29822.jpg', 'jpg', margin + 108, margin + 8, 5, 5);
      doc.setFontSize(10);    
      doc.text('(53)', margin + 116, margin + 12); 
      doc.setFontSize(12);    
      doc.setFont('helvetica', 'bold');  
      doc.text('3035-1977', margin + 123, margin + 12); 
      doc.setFontSize(10);
      doc.text('Rua Jaguarão, 542 - Cassino - CEP: 96205-060 - Rio Grande/RS', margin + 36, margin + 20);       
      doc.line(margin + 35, margin + 22, margin + 143, margin + 22);
      doc.text('CNPJ: 18.371.003/0001-05', margin + 65, margin + 27); 
      
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text('NOTA DE LEILÃO', rectX + 3, margin + 5); 
      
      doc.setFontSize(20);
      doc.text('%', rectX + 3, margin + 17); 
      doc.text('N: 3152', rectX + 10, margin + 17); 
      
      doc.setFontSize(7);
      doc.setFont('helvetica', 'normal');
      doc.text('CONTRATO DE COMPRA E VENDA', rectX, rectY + 21); 
      doc.text('COM RESERVA DE DOMÍNIO', rectX + 3, rectY + 25); 
      
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text('LEILÃO: ', margin, margin + 40);
      doc.setFont('helvetica', 'normal');
      doc.text(leilao.LEILAO || '', margin + 25, margin + 40);
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(15);    
      doc.text('LOTE', 180, margin + 40);
      doc.setFontSize(20);    
      doc.text(lote.LOTEXX || '', 183, margin + 48);
      
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text('LEILOEIRO: ', margin, margin + 45);
      doc.setFont('helvetica', 'normal');
      doc.text(leilao.LEILOE || '', margin + 25, margin + 45);

      doc.setFont('helvetica', 'bold');
      doc.text('LOCAL: ', margin, margin + 50);
      doc.setFont('helvetica', 'normal');
      doc.text(leilao.ENDERE || '', margin + 25, margin + 50);

      doc.setFont('helvetica', 'bold');
      doc.text('DATA: ', 130, margin + 50);
      doc.setFont('helvetica', 'normal');
      doc.text(leilao.DATLEI || '', 145, margin + 50);

      doc.setFont('helvetica', 'bold');
      doc.text('COMPRADOR: ', margin, margin + 55);
      doc.setFont('helvetica', 'normal');
      doc.text('', margin + 25, margin + 55);
      doc.setFont('helvetica', 'bold');
      doc.text('CIDADE: ', 130, margin + 55);
      doc.setFont('helvetica', 'normal');
      doc.text('', 145, margin + 55);

      doc.setFont('helvetica', 'bold');
      doc.text('VENDEDOR: ', margin, margin + 60);
      doc.setFont('helvetica', 'normal');
      doc.text(lote.NOMEXX, margin + 25, margin + 60);
      doc.setFont('helvetica', 'bold');
      doc.text('CIDADE: ', 130, margin + 60);
      doc.setFont('helvetica', 'normal');
      doc.text(lote.NOMECIDADE + '/'+ lote.NOMEESTADO, 145, margin + 60);
      
      doc.autoTable({
        startY: margin + 63,
        startX: 0,
        head: [['QTD.', 'ANIMAL']],
        body: [['1', lote.DESLOT || '']],
        theme: 'plain',
        styles: { fontSize: 8, halign: 'left', valign: 'middle', lineWidth: 0.1 },
        columnStyles: {
          0: { cellWidth: 40 }, 
          1: { cellWidth: 145 }  
        }
      });
      doc.autoTable({
        startY: margin + 77,
        startX: 0,
        head: [['SBB/HBB', 'RP/TAT.', 'SEXO', 'NASC.', 'CATEGORIA']],
        body: [
        [lote.SBBXXX || '', lote.RPXXX || '', lote.CATEGO || '', lote.DATNAS || '', lote.DESCRICAO || '' ],      
        ],
        theme: 'plain',
        styles: { fontSize: 8, halign: 'left', valign: 'middle', lineWidth: 0.1 },
        columnStyles: {
          0: { cellWidth: 25 }, 
          1: { cellWidth: 25 }, 
          2: { cellWidth: 25 }, 
          3: { cellWidth: 25 }, 
          4: { cellWidth: 85 }  
        }
      });
      doc.autoTable({
        startY: margin + 91,
        startX: 0,
        head: [['OBSERVAÇÕES']],
        body: [
        [''],      
        ],
        theme: 'plain',
        styles: { cellWidth: 185, fontSize: 8, halign: 'left', valign: 'middle', lineWidth: 0.1 },
      });

      doc.setLineWidth(0.5);
      doc.rect(margin, margin + 107, 150, 38); 
      doc.rect(157, margin + 107, 47, 19); 
      doc.rect(157, margin + 126, 47, 19); 
    
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');    
      doc.text('CONDIÇÕES DE PAGAMENTO', margin + 55, margin + 112);
      doc.setFontSize(9);
      doc.setFont('helvetica', 'normal');    
      doc.text('2 + 2 + 46 PARCELAS', margin + 2, margin + 118);
      doc.text('AVISTA COM 30% DE DESCONTO ', margin + 2, margin + 124);
      doc.text('COMISSÃO DE ' + leilao.COMCOM  || '0', margin + 2, margin + 130);
      doc.text('OUTROS:', margin + 2, margin + 136);

      doc.setFontSize(9);
      doc.setFont('helvetica', 'normal');
      doc.text('VALOR LANCE', 160, margin + 112);
      doc.setFontSize(18);
      doc.setFont('helvetica', 'bold');
      doc.text('R$ ' + lance.toFixed(2).toString()  || '0', 160, margin + 120);

      doc.setFontSize(9);
      doc.setFont('helvetica', 'normal');
      doc.text('VALOR TOTAL', 160, margin + 130);
      doc.setFontSize(18);
      doc.setFont('helvetica', 'bold');

      const valorTotal = parseFloat(lance * 50);
      doc.text('R$ ' + valorTotal.toFixed(2).toString() || '', 160, margin + 138);

      doc.setFontSize(6);
      doc.setFont('helvetica', 'normal');
      doc.text('1) Imediatamente no ato da batida do martelo - venda - o(s) animal(is) da presente nota ficarão por conta e risco do comprador;', 5, margin + 148);   
      doc.text('2) As vendas a prazo serão feitas com reversa de dominio, ficando o comprador na posse dos animais aqui descritos, não podendo alinea-lo até que', 5, margin + 152);
      doc.text('seja quitada a ultima prestação, importando o presente contrato em confissão de divida por parte do comprador, no total da transação feita a prazo', 5, margin + 156);
      doc.text('3) Se o comprador faltar com o pagamento de qualquer das prestações, ficará constituído em mora podendo ser provida de execução do titulo extra-judicial', 5, margin + 160);
      doc.text('representado pelo presente contrato e requerida a busca e apresenção dos animais condicionais adquiridos, conforme o dispositivo artigo 1071 e parágrafo do Código de Processo Civil.', 5, margin + 164);
      doc.text('4) O comprador se declara ciente de suas obrigações com relação aos animais adquiridos, deveno zelar dos mesmos e comunicar imediatamente a morte ou desaparecimento de qualquer deles', 5, margin + 168);
      doc.text('sob pena de ser responsabilizado de acordo com a lei.', 5, margin + 172);
      doc.text('5) As Obrigaçãoes decorrentes deste contrato são válidas, para os contratantes, seus herdeiros e sucessores', 5, margin + 176);
      doc.text('6) O atraso no pagamento de qualquer prestação acarretará a cobrança de juros de mora à razão de 1% ao mês, multa de 10% sobre o seu valor e correção monetária conforme o índice oficiais', 5, margin + 180); 
      doc.text('7) A comissão de compra será de ' + leilao.COMCOM  || '0' + ' % sobre o valor da batida do martelo, cujo o pagamento deverá ser realizado à vista no momento do acerto de compras', 5, margin + 184);


      doc.setFontSize(8);
      doc.setFont('helvetica', 'bold');
      doc.text('De Acordo: ___________________________________________________________________', margin + 40, margin + 200);
      doc.setFontSize(6);
      doc.setFont('helvetica', 'normal');
      doc.text('Assinatura do Comprador', margin + 90, margin + 203);
      doc.setFontSize(7);
      doc.setFont('helvetica', 'bold');
      doc.text('CPF: ________________________________', margin + 40, margin + 210);

      const pdfOutput = doc.output('blob');
      const url = URL.createObjectURL(pdfOutput);
      window.open(url);    

    } catch (error) {p
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <Button 
      onClick={generatePDF} 
      style={{
        backgroundColor: '#007BFF',
        color: '#fff',        
      }} 
      startIcon={<PrintIcon />}
    >
      Nota de Leilão
    </Button>
  );
};

export default NotaPrint;