import * as SecureStore from "expo-secure-store";

async function setKey(key: string, value: any) {
  if (typeof value === "string") {
    await SecureStore.setItemAsync(key, value);
    return;
  }
  await SecureStore.setItemAsync(key, JSON.stringify(value));
  return;
}

async function getKey(key: string) {
  let result = await SecureStore.getItemAsync(key);
  if (result) {
    return JSON.parse(result);
  } else {
    // throw new Error("Erro ao obter keys");
    return null;
  }
}

async function wipeKey() {
  await SecureStore.deleteItemAsync("user");
  await SecureStore.deleteItemAsync("token");
}
export { setKey, getKey, wipeKey };
