import { View, Text, Linking } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ProductsStackParamList } from "../../Routes/routes";
import {  
  Button as ButtonPaper,
  Card,
  Paragraph,
  Provider,
  Portal,
  Modal,
  ActivityIndicator,
} from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { Lote } from "../../utils/types/Leilao.type";
import { getLancePorLote, getLeilao, getLeiloes, getStatusCliente, getUltimoLancePorLote, getUser, setLance } from "../../Services/api/AuthServices";
import { theme } from "../../Global/Theme";
import Menu from "../../components/Gerais/Menu/Menu";
import { Button, CardActions, Grid, Paper, Typography } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import styled from "@emotion/styled";
import moment from "moment";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.css';
import CountdownTimer from "./CountdownTimer";
import { getIp } from "../../Services/api/axios";
import { sendEmail } from "../../components/Email/Email";
import SignUpScreens from "../SignInScreens/SignInScreens";
import { useCookies } from "react-cookie";
import Check from "@mui/icons-material/Check";
import Login from "@mui/icons-material/Login";
import PriceCheck from "@mui/icons-material/PriceCheck";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import YoutubeIcon from "@mui/icons-material/YouTube";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Cancel from "@mui/icons-material/Cancel";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import useMixpanel from "../../hooks/useMixpanel";
import AlertModal from "../../components/Alerts/AlertModal";

type Props = NativeStackScreenProps<ProductsStackParamList, "SaleScreen">;

export default function SaleScreen({ route }: Props) {

  const { trackEvent } = useMixpanel('d70609b52efef69ee6459b93c344f0c9');
  const localizacao = window.location.href;  
  const urlFinal = getLeilaoParameter(localizacao);
  
  const { setState, state } = useContext(AuthContext);
  const [cookies, setCookie] = useCookies(["macedo"]);
  const [visible, setVisible] = useState(false);
  const { leilao } = route.params;

  const [ dadosleilao, setdadosLeilao] = useState(null);
  const [ dadosDatlei, setdadosDatlei] = useState(null);
  const [ dadosObservacoes, setdadosObservacoes] = useState(null);
  const [ dadosEncerramento, setdadosEncerrameto] = useState(null);
  const [ dadosTransmissao, setdadosTransmissao] = useState(null);
  const [ dadosCatalogo, setdadosCatalogo] = useState(null);
  const [ dadosRegulameto, setdadosRegulamento] = useState(null);
  const [ dadoslink1, setdadosLink1] = useState(null);
  const [ dadoslink2, setdadosLink2] = useState(null);


  const [currentSale, setCurrentSale] = useState<Lote | null>();
  const [multiplo, setMultiplo] = useState(null);
  const [lanceFinal, setLanceFinal] = useState(0);
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalMsg, setErrorModalMsg] = useState("");
  const [loadingSubmition, setLoadingSubmition] = useState(false);
  const [loadingLeilao, setLoadingLeilao] = useState(false);  
  const [errorFetch, setErrorFetch] = useState(true);

  const [visibleGeracao, setVisibleGeracao] = useState(false);
  const [urlGeracao, seturlGeracao] = useState(null);
  const [visibleVideo, setVisibleVideo] = useState(false);
  const [urlVideo, seturlVideo] = useState(null);
  const [visibleImagem, setVisibleImagem] = useState(false);
  const [visibleDetalhes, setVisibleDetalhes] = useState(false);
  const [visibleRegulamento, setVisibleRegulamento] = useState(false);
  const [textoComentario, settextoComentario] = useState(null);
  const [lanceMaximo, setLanceMaximo] = useState(0);
  const [textoNome, settextoNome] = useState(null);
  const [textoFiliacao, settextoFiliacao] = useState(null);
  const [textoSexo, settextoSexo] = useState(null);
  const [textoSbb, settextoSbb] = useState(null);
  const [textoRp, settextoRp] = useState(null);
  const [textoNascimento, settextoNascimento] = useState(null);

  const [textoPelagem, settextoPelagem] = useState(null);
  const [textoRegulamento, settextoRegulamento] = useState(null);
  const [lanceAtual, setLanceAtual] = useState(null);
  const [idLote, setidLote] = useState(null);
  const [ip, setIp] = useState(null);
  
  const [itensLote, setItensLote] = useState([]);
  const [loadingLance, setLoadingLance] = useState(false);
  const [encerrado, setEncerrado] = useState(false);
  const [modalStatusCliente, setModalStatusCliente] = useState(false);
  const [closeStatusCliente, setCloseStatusCliente] = useState(false);
  const [ isLanceMaximo, setIsLanceMaximo] = useState(false);
  const [ lanceReload, setLanceReload] = useState(0);
  const [ loadingLanceReload, setLoadingLanceReload ] = useState(false);
  

  const [ loading10, setLoading10 ] = useState(false);
  const [ loading20, setLoading20 ] = useState(false);
  const [ loading50, setLoading50 ] = useState(false);
  const [ loading100, setLoading100 ] = useState(false);
  const [ loadingMax, setLoadingMax ] = useState(false);
  let isFetching = false;

  function getLeilaoParameter(url) {
    try {      
        const parsedUrl = new URL(url);
        const params = parsedUrl.pathname;        
        return params.replace('/leilao/', '');
    } catch (error) {
        console.error('URL inválida:', error);
        return null;
    }
  }
  
  const isMobile = () => {
    if (innerWidth < 640) {
      return true
    } else {
      return false
    }
  };
  
  useEffect(() => {
    setIp(getIp);
  }, [])

  function replaceAll(string, search, replace) {
    return string?.split(search).join(replace);
  }

  function removeCharacter(props) {
    let newString = undefined;
    newString = replaceAll(props, '(', '');
    newString = replaceAll(newString, ')', '');
    newString = replaceAll(newString, ' ', '');
    newString = replaceAll(newString, '.', '');
    return newString;
  }  

  const containerStyle = {
    backgroundColor: "white",
    padding: 15,
    height: 300,
    borderRadius: 12,
  };

  const containerStyleCard = {
    backgroundColor: "white",
    padding: 15,
    height: '80%',
    width: '95%',
    borderRadius: 12,
  };

  const containerStyleMinor = {
    backgroundColor: "white",
    padding: 15,
    height: isMobile() ? '90%' : '500px',
    width: isMobile() ? '90%' : '580px',
    borderRadius: 12,
  };

  async function sendPushNotification(expoPushToken, 
                                      sonud, 
                                      title, 
                                      subtitle,
                                      body,
                                      leilao,
                                      lote,
                                      descricao_lote) {

    let MeuToken = expoPushToken.map(log => log.token)
    const message = {
      to: MeuToken[0],
      sound: sonud,
      title: title,
      subtitle: subtitle,
      body: body,
    };  

    await fetch('https://exp.host/--/api/v2/push/send', {
      method: 'POST',
      headers: {
      Accept: 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': ' https://www.macedoleiloesrurais.com.br/',
      },
      body: JSON.stringify(message),
      });
  }

  async function verificarLance(lote, lotexx, deslot) {
    trackEvent('Verificando Lance ' + lotexx + ' ' +deslot, { Screen: 'SalesScreen' });
    let lanceUltimo = await getUltimoLancePorLote(lote);
    sendEmail(lanceUltimo[0]?.EMAILX, lanceUltimo[0]?.NOMEXX, `Seu lance no lote: ${lotexx} - ${deslot} foi coberto.Acesse nosso site e de seu lance!!!`,'','');
    sendEmail(state.user.emailx, state.user.emailx, `Lance efetuado no lote: ${lotexx} - ${deslot} , com sucesso!!!`,'','');
  };

  const hideModal = () => {
    setCurrentSale(null);
    setLanceFinal(0);    
    setMultiplo("0");
    setLanceReload(0);
    setVisible(false);
  };
  const hideModalGeracao = () => {
    setVisibleGeracao(false);
  };
  const hideModalVideo = () => {
    setVisibleVideo(false);
  };
  const hideModalImagem = () => {
    setVisibleImagem(false);
  };
  const hideModalDetalhes = () => {
    setVisibleDetalhes(false);
  };
  const hideModalRegulamento = () => {
    setVisibleRegulamento(false);
  };
  
  function atualizarLanatu(lotes, valores) {  
    const valoresMap = new Map(valores.map(item => [item.IDLOTE, item.VALOR]));
    
    lotes.forEach(lote => {
        if (valoresMap.has(lote.ID)) {
            lote.LANATU = valoresMap.get(lote.ID);
        }
    });

    return lotes;
  }

  async function handleShowDialogLance(ID) {    
    setLoadingLance(true);
        
    const loteFiltrado = itensLote.filter((item) => item.ID === ID)

    if (loteFiltrado[0].LANMAX > 0) {
      setIsLanceMaximo(true)
    } else {
      setIsLanceMaximo(false)
    }
    
    const ultimoLance = await getUltimoLancePorLote(ID);   
    const valor = (ultimoLance[0] && ultimoLance[0].VALOR) || 0; 
    setLanceReload(valor);
    if (ultimoLance.length > 0) { 
      if (ultimoLance[0].VALOR > loteFiltrado[0].LANATU) {      
          await fetchLeilao(loteFiltrado[0].IDLEILAO);

          <Modal                
            visible={visible}
            onDismiss={hideModal}
            contentContainerStyle={{
              backgroundColor: "white",
              padding: 15,
              width: 200,
              height: 200,
              borderRadius: 12,
            }}
          >
            <View>
                <Text>Este lote já possui um lance maior, por este motivo atualizamos sua tela.</Text>          
            </View>
          </Modal>            
      }
    }    
    setidLote(ID);
    setLanceAtual(loteFiltrado[0].LANATU);
    trackEvent('Abrindo Lance por Lote ' + loteFiltrado[0].LOTEXX + ' ' + loteFiltrado[0].DESLOT + ' ' + state.user.nomexx, { Screen: 'SalesScreen' });
    if (state.loggedIn) {
      setLoadingLance(false);
      setVisible(true);
    }
    ReloadLances();
  }

  function ReloadLances() {
    setLoadingLanceReload(true);
    fetchDataLance();
    setLoadingLanceReload(false);    
  }

  function handleShowDialogGeracao(sbb) {
    trackEvent('Abrindo Quinta Geração Lote ' + sbb, { Screen: 'SalesScreen' });
    const urlComTimestamp = "https://macedo.s3.us-east-2.amazonaws.com/" + sbb + ".pdf?" + new Date().getTime();
    seturlGeracao(urlComTimestamp);  
    setVisibleGeracao(true);         
    ReloadLances();                  
  }

  function handleShowDialogVideo(video) {
    trackEvent('Abrindo Vídeo Lote ' + video , { Screen: 'SalesScreen' });
    seturlVideo(video);
    setVisibleVideo(true);
    if (isMobile()) {
      window.open("https://www.youtube.com/embed/" + video + "?autoplay=1&autohide=1&fs=1&rel=0&hd=1&wmode=opaque&enablejsapi=1&ps=docs&controls=1", "", "width='90%',height='85%'")
    } else {
      let height = 500;
      let width = 500;
      var left = (screen.width - width) / 2;
      var top = (screen.height - height) / 2;
      window.open("https://www.youtube.com/embed/" + video + "?autoplay=1&autohide=1&fs=1&rel=0&hd=1&wmode=opaque&enablejsapi=1&ps=docs&controls=1", "center window", 'resizable = yes, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);
    }
    ReloadLances();
  }

  const handleEncerradoChange = (isEncerrado) => {    
    setEncerrado(isEncerrado);
  };

  function handleShowDialogTransmissao(props) {
    trackEvent('Abrindo Transmissão Leilão ' + props.link1 || props.link2 , { Screen: 'SalesScreen' });
    window.open(props.link1 || props.link2, "", "width=450,height=300");
    ReloadLances();
  }
  
  function handleShowDialogCatalogo(transmissao) {    
    trackEvent('Abrindo Catalogo ' + transmissao , { Screen: 'SalesScreen' });
    window.open(transmissao, "", "width=800,height=800");
    ReloadLances();
  }
  
  function handleShowDialogImagem(id) {
    trackEvent('Abrindo Imagens Lote ' + id , { Screen: 'SalesScreen' });
    setidLote(id);
    setVisibleImagem(true);
    ReloadLances();
  }
  function handleShowDialogDetalhes(nome, filiacao, sexo, sbb, rp, nascimento, pelagem, comentario) {
    trackEvent('Abrindo Detalhes Lote ' + nome , { Screen: 'SalesScreen' });
    let sexoTratado = sexo;
    settextoNome(nome)
    settextoFiliacao(filiacao)
    if (sexo === 'F') {
      sexoTratado = 'Femea'
    }
    if (sexo === 'M') {
      sexoTratado = 'Macho'
    }
    if (sexo === 'C') {
      sexoTratado = 'Castrado'
    }
    if (sexo === 'S') {
      sexoTratado = 'Cobertura'
    }
    settextoSexo(sexoTratado);
    settextoSbb(sbb)
    settextoRp(rp)
    settextoNascimento(nascimento)
    settextoPelagem(pelagem)
    settextoComentario(comentario)
    setVisibleDetalhes(true);
  }
  function handleShowDialogRegulamento(regulamento) {
    trackEvent('Abrindo Regulamento', { Screen: 'SalesScreen' });
    settextoRegulamento(regulamento)
    setVisibleRegulamento(true);
    ReloadLances();
  }

  const handleCloseStatusCliente = () => {
    setModalStatusCliente(false);
  }

    const changeLance = async (props) => {           
      if (props.valor === 10) {
        setLoading10(true);
      }
      if (props.valor === 20) {
        setLoading20(true);
      }
      if (props.valor === 50) {
        setLoading50(true);
      }
      if (props.valor === 100) {
        setLoading100(true);
      }
      if (props.lanceMaximo) {
        setLoadingMax(true);
      }
      
      const loteFiltrado = itensLote.filter((item) => item.ID === idLote)   
      
      const statusCliente = await getStatusCliente(state.user.id);    
      if (!statusCliente) {
        setLoadingLance(false);
        setVisible(false);
        setLoadingSubmition(false);
        setErrorModalMsg(null);
        setErrorModal(true);           
        setLanceFinal(0);
        setModalStatusCliente(true);      
        if (props.valor === 10) {
          setLoading10(false);
        }
        if (props.valor === 20) {
          setLoading20(false);
        }
        if (props.valor === 50) {
          setLoading50(false);
        }
        if (props.valor === 100) {
          setLoading100(false);
        }
        if (props.lanceMaximo) {
          setLoadingMax(false);
        }      
        return;
      }
      
      trackEvent('Alterando Valor do Lance Lote ' + idLote + ' ' + props.valor, { Screen: 'SalesScreen' });    
      if (lanceFinal >= 0) {
        setLanceFinal(lanceFinal + props.valor);        
      } else {        
        setLanceFinal(loteFiltrado[0].LANATU);     
      }
      setLanceMaximo(loteFiltrado[0].LANMAX);      
    
      let newText = 0;
      if (props.lance_maximo) {
      newText =  lanceMaximo;
      } else {
      newText = parseFloat(lanceFinal + props.valor);
      }    
  
      if (props.valor === 10) {
        setLoading10(false);
      }
      if (props.valor === 20) {
        setLoading20(false);
      }
      if (props.valor === 50) {
        setLoading50(false);
      }
      if (props.valor === 100) {
        setLoading100(false);
      }
      if (props.lanceMaximo) {
        setLoadingMax(false);
      }
  
      setLoadingLance(false);
    //  ReloadLances();
    }
  async function handleSubmit(idlote, lanmax, vendido) {
    if (lanceFinal == 0) {
      setErrorModal(true);
      setErrorModalMsg("Informe um valor para o lance.")
      return;
    }
    
    const lotefiltrado = itensLote.filter((item) => item.ID === idLote)      
    try {
      setLoadingSubmition(true);
      if (lanceFinal > lanmax && vendido === "S") {
      } else
        if (lanceFinal > lanmax) {
          setErrorModalMsg("Lote Vendido.");
          setLoadingSubmition(false);
          return;
        } else {
          const data = {
            id: 0,
            idlote: `${idlote}`,
            datalance: new Date().toISOString(),
            idcliente: `${state.user.id}`,
            ip: ip,
            origem: "APP",
            valor: lotefiltrado[0].LANATU + lanceFinal,
          };

          const pushClientLanceAnterior = await getUltimoLancePorLote(idLote);
          if (pushClientLanceAnterior[0] != undefined) {
          if (pushClientLanceAnterior[0].IDCLIENTE != state.user.id) {  
            try {          
            await sendPushNotification(
              pushClientLanceAnterior,
              'default',
              'Macedo Leilões informa',
              'Seu lance foi ultrapassado',
              'Acesse o app e de seu lance',
              lotefiltrado[0].LEILAO,
              lotefiltrado[0].ID,
              lotefiltrado[0].LOTEXX + ' ' + lotefiltrado[0].DESLOT
              );  
              } catch (error) {
                console.log(error);
              }                        
            }
          }      

          await setLance(data);
          trackEvent('Lance Confirmado ' + state.user.emailx + ' ' + state.user.nomexx, { Screen: 'SalesScreen' });               

          sendEmail(state.user.emailx, state.user.nomexx, `Lance efetuado com sucesso`,'','');

          verificarLance(idlote, lotefiltrado[0].LOTEXX,lotefiltrado[0].DESLOT);

          setVisible(false);
          setLoadingSubmition(false);
          setErrorModalMsg(null);
          setErrorModal(true);           
          setLanceFinal(0);
          window.location.reload();
          return;
        }
    } catch (error) {
      setLoadingSubmition(false);
      setErrorModalMsg(`${error}`);
      setErrorModal(true);
    }
  }

  async function fetchLeilao(id: string) {
    try {
      setLoadingLeilao(true);

      const dataLeilao = await getLeiloes();
      const leilaoFilter = dataLeilao.filter((item) => item.ID == id);
      
      setdadosLeilao(leilaoFilter[0].LEILAO);
      setdadosDatlei(leilaoFilter[0].DATLEI);
      setdadosEncerrameto(leilaoFilter[0].HORA_FECHAMENTO_PRE);
      setdadosObservacoes(leilaoFilter[0].OBSERVACOES);
      setdadosRegulamento(leilaoFilter[0].REGULAMENTO);
      setdadosTransmissao(leilaoFilter[0].TRANSMISSAO);
      setdadosCatalogo(leilaoFilter[0].URLCATALOGO);
      setdadosLink1(leilaoFilter[0].LINKTRANSMISSAO1);
      setdadosLink2(leilaoFilter[0].LINKTRANSMISSAO2);                  
      
      document.title = 'Leilão: ' + leilaoFilter[0].LEILAO;

      let dataLotes = [];
     
      dataLotes = await getLeilao(id);  

      dataLotes.sort(function(a, b){
        return a.LOTEXX - b.LOTEXX
      });

      if (dataLotes.length > 0) {        
        setItensLote(dataLotes)
      } else
      {
        setItensLote([]);
      }
      //filter itensLote to IDLEILAO
      itensLote.filter((item) => item.IDLEILAO == id);   

      setLoadingLeilao(false);
      setErrorFetch(false);
    } catch (e) {
      setLoadingLeilao(false);
      setErrorFetch(true);
    }
  }

  useEffect(() => {
    (async () => {    
      if (leilao.ID != undefined) {
        await fetchLeilao(`${leilao.ID}`);
      } else {
        await fetchLeilao(urlFinal);
      }            
    })();
  }, [leilao]);

  useEffect(() => {
    console.log('passou');
    const fetchData = async () => {
      const meuCookies = cookies.macedo;
      if (meuCookies != undefined) {
        const user = await getUser(cookies.macedo);        
        setState({ user, loggedIn: true, token: '' });
      }
    }
    fetchData()
      .catch(console.error);
  }, []);

  const ButtonLancar = (props) => {
    if (encerrado) {
      return (
        <Button
          style={{             
            backgroundColor: theme.colors.primary, 
            color: theme.colors.secondary, 
            textAlign: 'center',
            width: '100%',
            marginTop: 5,                                                                                                               
          }}
          startIcon={
            <CalendarMonth/>
          }                                            
      >
        ENCERRADO
      </Button>      
      )
    }
    if (props.LANATU === props.LANMAX) {
      return (
        <Button
          style={{             
            backgroundColor: 'firebrick', 
            color: 'white', 
            textAlign: 'center',
            width: '100%',   
            marginTop: 5,                                                                                                           
          }}                                                      
        >
          VENDIDO
        </Button>      
      )
    }
    if (state.loggedIn) {
      return (
        <Button          
          style={{             
            backgroundColor: theme.colors.tertiary,
            color: 'white', 
            textAlign: 'center',
            width: '100%',  
            marginTop: 5,                                                       
          }}
          startIcon={
            <PriceCheck/>
          }   
          onClick={() => handleShowDialogLance(props.ID)}                                         
        >
          DAR LANCE
        </Button>      
      )
    }
    if (!state.loggedIn) {
      return (
        <SignUpScreens 
        isSales={true}
        isMobile={false}
      />    

    )
  }}
      
  const fetchDataLance = async () => {    
    const dataLance = await getLancePorLote(urlFinal);              
    const lancefiltrado = dataLance.filter((item) => item.IDLOTE === idLote)      
    const valor = (lancefiltrado[0] && lancefiltrado[0].VALOR) || 0;
    if (visible && idLote) {
      setLanceReload(valor);
    }
    atualizarLanatu(itensLote,dataLance);    
  }      
  
  React.useEffect(() => {
    const closeOnEscapePressed = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        hideModal();
        hideModalGeracao();
        hideModalVideo();
        hideModalImagem();
        hideModalRegulamento();
        hideModalDetalhes();
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () =>
      window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  const ItemDadosLeiloes = styled('span')(({ theme }) => ({
    textAlign: 'center',    
  }));
  
  return (
    <View style={{ flex: 1, backgroundColor: theme.colors.secondary }}>      
      <Provider>     
        {loadingLeilao ? (
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ActivityIndicator animating={true} color={theme.colors.primary} />
            <Text 
              style={{ 
                color: theme.colors.primary,
                margin: 10
              }}
            >Buscando {dadosleilao}</Text>
          </View>
        ) : errorFetch ? (
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ActivityIndicator animating={true} color="white" />
          </View>
        ) : (
          <>
            <Menu/>
            <ScrollView
              userSelect="auto"
              contentInset={{ bottom: 100 }}
              contentContainerStyle={{ paddingBottom: 100 }
              }
            >              
              {
                innerWidth > 640 &&
                  <View 
                    style={{
                    flex: 1,
                    marginLeft: 'auto',
                    }}                              
                  >
                    <CountdownTimer 
                        targetDate={dadosEncerramento}  
                        onEncerradoChange={handleEncerradoChange}                      
                    />
                  </View> 
              }                             
              <Typography style={{ marginLeft: 10 }} variant="h6" gutterBottom>
                Leilão: { dadosleilao }
              </Typography>
              <Typography style={{ marginLeft: 10 }} variant="subtitle1" gutterBottom>
                Data: {moment(dadosDatlei).format('DD/MM/YYYY')}
              </Typography>
              <Typography style={{ margin: 10, textAlign: 'justify' }} variant="subtitle1">
                {dadosObservacoes}
              </Typography>              

              <Grid container>
                <Grid item xs={12} md={3}>
                  <ItemDadosLeiloes>
                    <Button
                      style={{ marginRight: 'auto', 
                               backgroundColor: theme.colors.secondary, 
                               color: 'black', 
                               textAlign: 'center',
                               width: '100%',                                            
                      
                               marginLeft: 10 }}
                      startIcon={
                        <CalendarMonth/>
                      }                                            
                    >
                      {"ENCERRAMENTO: " + dadosEncerramento}
                    </Button>
                  </ItemDadosLeiloes>
                </Grid>
                <Grid item xs={12} md={3}>
                  <ItemDadosLeiloes>
                    <Button                      
                      disabled={!dadoslink1 && !dadoslink2}
                      style={{ marginRight: 'auto', 
                      backgroundColor: theme.colors.secondary, 
                      textAlign: 'center',
                      width: '100%',                                            
                      color: 'black', 
                      marginLeft: 10 }}
                      startIcon={
                        <YoutubeIcon/>
                      }                                            
                      onClick={() => handleShowDialogTransmissao(dadoslink1 || dadoslink2)}
             
                    >
                      {"TRANSMISSÃO: " + dadosTransmissao}
                    </Button>
                  </ItemDadosLeiloes>
                </Grid>
                <Grid item xs={12} md={3}>
                  <ItemDadosLeiloes>
                    <Button                    
                      disabled={!dadosCatalogo}
                      style={{ marginRight: 'auto', 
                      backgroundColor: theme.colors.secondary, 
                      textAlign: 'center',
                      width: '100%',                                            
                      color: 'black', 
                      marginLeft: 10 }}
                      startIcon={
                        <PictureAsPdfIcon/>
                      }                                            
                      onClick={() => handleShowDialogCatalogo(dadosCatalogo)}
             
                    >
                      {"CATALOGO PDF"}
                    </Button>
                  </ItemDadosLeiloes>
                </Grid>
                <Grid item xs={12} md={3}>
                  <ItemDadosLeiloes>
                    <Button
                      style={{ marginRight: 'auto', 
                      textAlign: 'center',
                      width: '100%',                                            
                      backgroundColor: theme.colors.secondary, 
                      color: 'black', 
                      marginLeft: 10 }}
                      startIcon={
                        <SummarizeIcon/>
                      }                                            
                      onClick={() => handleShowDialogRegulamento(dadosRegulameto)}
                    >
                      REGULAMENTO
                    </Button>
                    <Portal>
                      <Modal
                        visible={visibleRegulamento}
                        contentContainerStyle={containerStyleCard}
                        style={{
                          height: '100vh',
                          justifyContent: "center",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <ScrollView>
                          <Typography variant="subtitle1" gutterBottom>
                            {textoRegulamento}
                          </Typography>
                        </ScrollView>
                        <Button
                          style={{ marginRight: 'auto', 
                          backgroundColor: 'black', 
                          color: 'white', 
                          width: '100%' }}
                          startIcon={
                            <ArrowBackIcon/>
                          }                                            
                          onClick={() => hideModalRegulamento()}>
                          Voltar
                        </Button>
                      </Modal>
                    </Portal>
                  </ItemDadosLeiloes>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>                  
                    {
                    innerWidth < 640 &&  
                        <CountdownTimer 
                            targetDate={dadosEncerramento}  
                            onEncerradoChange={handleEncerradoChange}                      
                        />
                      
                    }
                    <Button
                      style={{                         
                        textAlign: 'center',
                        width: '100%',                                            
                        backgroundColor: theme.colors.primary, 
                        color: 'white', 
                        marginTop: 10,
                        borderRadius: 0,
                     }}
                      startIcon={
                        <SummarizeIcon/>
                      }                                            
                    >
                      EQUIPE COMERCIAL
                    </Button>                  
                </Grid>
              </Grid>
              
              <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={6} md={4}>
                  <ItemDadosLeiloes>
                    <Button
                      style={{ marginRight: 'auto', 
                      textAlign: 'center',
                      width: '99%',                                            
                      backgroundColor: theme.colors.secondary, 
                      color: 'black', 
                      margin: 5,                
                      fontSize: '10px'    
                      }}
                      startIcon={
                        <WhatsApp/>
                      }                                            
                      onClick={() => {
                        Linking.openURL('https://wa.me/55' + removeCharacter('(53) 3035-1977'));
                      }}
                    >
                      Escritório  <br></br> (53)3035-1977
                    </Button>
                  </ItemDadosLeiloes>
                </Grid>
                <Grid item xs={6} md={4}>
                  <ItemDadosLeiloes>
                    <Button
                      style={{ marginRight: 'auto', 
                      textAlign: 'center',
                      width: '99%',                                            
                      backgroundColor: theme.colors.secondary, 
                      color: 'black', 
                      margin: 5,                
                      fontSize: '10px'    
                      }}
                      startIcon={
                        <WhatsApp/>
                      }                                            
                      onClick={() => {
                        Linking.openURL('https://wa.me/5553999998901');
                      }}
                    >
                      Santiago Macedo  <br></br> (53)99999-8901
                    </Button>
                  </ItemDadosLeiloes>
                </Grid>
                <Grid item xs={6} md={4}>
                  <ItemDadosLeiloes>
                    <Button
                      style={{ marginRight: 'auto', 
                      textAlign: 'center',
                      width: '99%',                                            
                      backgroundColor: theme.colors.secondary, 
                      color: 'black', 
                      margin: 5,                
                      fontSize: '10px'               
                      }}
                      startIcon={
                        <WhatsApp/>
                      }                                            
                      onClick={() => {
                        Linking.openURL('https://wa.me/5553999065590');
                      }}
                    >
                      Marcelo Fonseca  <br></br> (53)99906-5590
                    </Button>
                  </ItemDadosLeiloes>
                </Grid>
                <Grid item xs={6} md={4}>
                  <ItemDadosLeiloes>
                    <Button
                      style={{ marginRight: 'auto', 
                      textAlign: 'center',
                      width: '99%',                                            
                      backgroundColor: theme.colors.secondary, 
                      color: 'black', 
                      margin: 5,                
                      fontSize: '10px'               
                      }}
                      startIcon={
                        <WhatsApp/>
                      }                                            
                      onClick={() => {
                        Linking.openURL('https://wa.me/5555999382448');
                      }}
                    >
                      Thiago Lima  <br></br> (55)99938-2448
                    </Button>
                  </ItemDadosLeiloes>
                </Grid>
                <Grid item xs={6} md={4}>
                  <ItemDadosLeiloes>
                    <Button
                      style={{ marginRight: 'auto', 
                      textAlign: 'center',
                      width: '99%',                                            
                      backgroundColor: theme.colors.secondary, 
                      color: 'black', 
                      margin: 5,                
                      fontSize: '10px'               
                      }}
                      startIcon={
                        <WhatsApp/>
                      }                                            
                      onClick={() => {
                        Linking.openURL('https://wa.me/5555996277432');
                      }}
                    >
                      Arthur Freitas <br></br> (55)99627-7432
                    </Button>
                  </ItemDadosLeiloes>
                </Grid>
                <Grid item xs={6} md={4}>
                  <ItemDadosLeiloes>
                    <Button
                      style={{ marginRight: 'auto', 
                      textAlign: 'center',
                      width: '99%',                                            
                      backgroundColor: theme.colors.secondary, 
                      color: 'black', 
                      margin: 5,                
                      fontSize: '10px'               
                      }}
                      startIcon={
                        <WhatsApp/>
                      }                                            
                      onClick={() => {
                        Linking.openURL('https://wa.me/5553999616345');
                      }}
                    >
                      Marcelo Solé <br></br> (53)99961-6345
                    </Button>
                  </ItemDadosLeiloes>
                </Grid>
              </Grid>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
                alignItems="center"
                justifyContent="center">
                {itensLote.map((lote) => (                  
                    <Card
                      style={{
                        margin: 5,
                        borderRadius: 12,
                        backgroundColor: theme.colors.primary
                      }}
                      key={lote.ID}
                    >
                      <Avatar sx={{
                        width: 35,
                        height: 35,
                        bgcolor: "black",
                        fontSize: 10,                        
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '10px'
                      }}
                      >
                        {lote.LOTEXX}
                      </Avatar>
                      <Typography
                        translate='no'                      
                        style={{
                          display: 'inline-block',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          width: '255px',
                          color: "black",
                          margin: 5,
                          fontWeight: 'bold',
                          textAlign: 'center',
                          
                        }}
                       >{lote.DESLOT}
                       </Typography>

                      <Card.Cover
                        theme={{ roundness: 0 }}
                        style={{ margin: 5, borderRadius: 12 }}
                        source={{
                          uri:
                            "https://macedo.s3.us-east-2.amazonaws.com/lote_1_img_" +
                            lote.ID +
                            ".jpg" +
                            "?timestamp=" + new Date().getTime(), 
                        }}
                      />
                      <Card.Content>
                        <Paragraph style={{ color: "black", fontWeight: 'bold', textAlign: 'center' }}>
                          ÚLTIMO LANCE
                        </Paragraph>
                        <Paragraph
                          style={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}>
                          {lote.LANATU
                            ? `${lote.LANATU.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}`
                            : "0,00"}
                        </Paragraph>

                        {lote.LANMAX > 0 &&
                          <>
                           <Paragraph style={{ color: "silver", textAlign: 'center' }}>
                            LANCE ALVO
                           </Paragraph>
                          <Paragraph style={{ color: "silver", fontWeight: 'bold', textAlign: 'center' }}>
                            {lote.LANMAX ?
                              `${lote.LANMAX?.toLocaleString("pt-br", { style: "currency", currency: "BRL", })}`
                              :
                              "R$ 0,00"
                            }
                          </Paragraph>
                          </>
                        }


                        <CardActions>
                          <Typography style={{ width: '25%', fontSize: 9, textAlign: 'center' }}>GERAÇÃO</Typography>
                          <Typography style={{ width: '25%', fontSize: 9, textAlign: 'center' }}>VÍDEOS</Typography>
                          <Typography style={{ width: '25%', fontSize: 9, textAlign: 'center' }}>IMAGENS</Typography>
                          <Typography style={{ width: '25%', fontSize: 9, textAlign: 'center' }}>DETALHES</Typography>
                        </CardActions>
                        <span style={{ height: '30px', marginBottom: '10px' }}>
                          <img
                            style={{ width: '40px', height: '40px', alignItems: "center", marginLeft: '10px', marginRight: '10px' }}
                            src="https://i.ibb.co/tbmt13H/btn-geracao.png"
                            alt="btn-video"
                            onClick={() => handleShowDialogGeracao(lote.SBBXXX)}
                          />
                          <Portal>
                            <Modal
                              dismissable
                              visible={visibleGeracao}
                              contentContainerStyle={containerStyleCard}
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                flex: 1,
                              }}
                            >
                              <iframe
                                src={urlGeracao}
                                height={'100%'}
                                width={'100%'}
                                style={{ marginBottom: '10px' }}
                              />
                              <Button
                                style={{ marginRight: 'auto', 
                                backgroundColor: 'black', 
                                color: 'white', 
                                width: '100%' }}
                                startIcon={
                                  <ArrowBackIcon/>
                                }                                            
                                onClick={() => hideModalGeracao()}>
                                Voltar
                              </Button>
                            </Modal>
                          </Portal>
                          <img
                            style={{ width: '40px', height: '40px', alignItems: "center", marginLeft: '10px', marginRight: '10px' }}
                            src="https://i.ibb.co/jyF93g9/btn-video.png"
                            alt="btn-video"
                            onClick={() => handleShowDialogVideo(lote.URLVIDEO)}
                          />
                          <img
                            style={{ width: '40px', height: '40px', alignItems: "center", marginLeft: '10px', marginRight: '10px' }}
                            src="https://i.ibb.co/Yk7PDsv/btn-imagens.png"
                            alt="btn-video"
                            onClick={() => handleShowDialogImagem(lote.ID)}
                          />
                          <Portal>
                            <Modal
                              dismissable
                              visible={visibleImagem}
                              contentContainerStyle={containerStyleMinor}
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                flex: 1,
                              }}
                            >
                              <View>
                                <Carousel>
                                  <Carousel.Item>
                                    <img
                                      style={{ width: isMobile() ? '100%' : '550px', height: isMobile() ? '70%' : '400px', borderRadius: '12px' }}
                                      src={"https://macedo.s3.us-east-2.amazonaws.com/lote_1_img_" + idLote + ".jpg"}
                                    />
                                  </Carousel.Item>
                                  <Carousel.Item>
                                    <img
                                      style={{ width: isMobile() ? '100%' : '550px', height: isMobile() ? '70%' : '400px', borderRadius: '12px' }}
                                      src={"https://macedo.s3.us-east-2.amazonaws.com/lote_2_img_" + idLote + ".jpg"}
                                    />
                                  </Carousel.Item>
                                  <Carousel.Item>
                                    <img
                                      style={{ width: isMobile() ? '100%' : '550px', height: isMobile() ? '70%' : '400px', borderRadius: '12px' }}
                                      src={"https://macedo.s3.us-east-2.amazonaws.com/lote_3_img_" + idLote + ".jpg"}
                                    />
                                  </Carousel.Item>
                                  <Carousel.Item>
                                    <img
                                      style={{ width: isMobile() ? '100%' : '550px', height: isMobile() ? '70%' : '400px', borderRadius: '12px' }}
                                      src={"https://macedo.s3.us-east-2.amazonaws.com/lote_4_img_" + idLote + ".jpg"}
                                    />
                                  </Carousel.Item>
                                </Carousel>
                              </View>
                              <Button
                                style={{                                   
                                  backgroundColor: 'black', 
                                  color: 'white', 
                                  width: '100%', 
                                  marginTop: 'auto'
                                }}
                                startIcon={
                                  <ArrowBackIcon/>
                                }                                            
                                onClick={() => hideModalImagem()}>
                                Voltar
                              </Button>
                            </Modal>
                          </Portal>
                          <img
                            style={{ width: '40px', height: '40px', alignItems: "center", marginLeft: '10px', marginRight: '10px' }}
                            src="https://i.ibb.co/3p9mgvv/btn-detalhes.png"
                            alt="btn-video"
                            onClick={() => handleShowDialogDetalhes(
                              lote.DESLOT,
                              lote.FILIACAO,
                              lote.CATEGO,
                              lote.SBBXXX,
                              lote.RPXXX,
                              lote.DATNAS,
                              lote.PELAGE,
                              lote.COMENTARIO)}
                          />
                          <Portal>
                            <Modal
                              onDismiss={hideModalDetalhes}
                              visible={visibleDetalhes}
                              contentContainerStyle={containerStyleMinor}
                              style={{
                                height: '90vh',
                                justifyContent: "center",
                                alignItems: "center",
                                flex: 1,
                              }}
                            >
                              <ScrollView userSelect="auto">
                              <Typography translate='no' variant="h5" gutterBottom>
                                {textoNome}
                              </Typography>
                              <Typography translate='no' variant="subtitle1" gutterBottom>
                                {textoFiliacao}
                              </Typography>
                              <Typography variant="subtitle1" gutterBottom>
                                SBB: {textoSbb}
                              </Typography>
                              <Typography variant="subtitle1" gutterBottom>
                                RP: {textoRp}
                              </Typography>
                              <Typography variant="subtitle1" gutterBottom>
                                Sexo: {textoSexo}
                              </Typography>
                              <Typography variant="subtitle1" gutterBottom>
                                Data Nascimento: {moment(textoNascimento).format('DD/MM/YYYY')}
                              </Typography>
                              <Typography translate='no' variant="subtitle1" gutterBottom>
                                Pelagem: {textoPelagem}
                              </Typography>
                              <Typography translate='no' variant="subtitle1" gutterBottom>
                                {textoComentario}
                              </Typography>
                              </ScrollView>
                              <Button
                                style={{ marginRight: 'auto', 
                                backgroundColor: 'black', 
                                color: 'white', 
                                width: '100%' }}
                                startIcon={
                                  <ArrowBackIcon/>
                                }                                            
                                onClick={() => hideModalDetalhes()}>
                                Voltar
                              </Button>
                            </Modal>
                          </Portal>
                        </span>
                      </Card.Content>
                      {ButtonLancar(lote)}

                    </Card>                  
                ))}
              </Grid>
            </ScrollView>
          </>
        )}
        <Portal>
          <Modal
            dismissable
            visible={visible}
            contentContainerStyle={containerStyle}
            style={{
              height: 600,
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  color: "red",
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 5,
                  textAlign: "center",
                }}
              >
                Lance atual:{" "}
                {lanceReload ?
                  `${lanceReload.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}`
                  :
                  "R$ 0,00"
                }
              </Text>
              <Card.Actions>
              <ButtonPaper            
                  loading={loading10}
                  buttonColor={theme.colors.primary}
                  labelStyle={{ color: "white" }}                             
                  onPress={() => changeLance({ valor : 10, lance_maximo: false })}
                  style={{ marginRight: '5px' }}                                                      
                >
                  R$10
                </ButtonPaper>
                <ButtonPaper      
                  loading={loading20}
                  buttonColor={theme.colors.primary}
                  labelStyle={{ color: "white" }}                             
                  onPress={() => changeLance({ valor : 20, lance_maximo: false })}
                  style={{ marginRight: '5px' }}                                                      
                >
                  R$20
                </ButtonPaper>
                <ButtonPaper
                  loading={loading50}
                  buttonColor={theme.colors.primary}
                  labelStyle={{ color: "white" }}                
                  onPress={() => changeLance({ valor : 50, lance_maximo: false })}
                  style={{ marginRight: '5px' }}                                                      
                >
                  R$50
                </ButtonPaper>
                <ButtonPaper
                  loading={loading100}
                  buttonColor={theme.colors.primary}
                  labelStyle={{ color: "white" }}                  
                  
                  onPress={() => changeLance({ valor : 100, lance_maximo: false })}
                  style={{ marginRight: '5px' }}                                                      
                >
                  R$100
                </ButtonPaper>
              </Card.Actions>
              {isLanceMaximo &&
                <Card.Actions style={{ margin: 0 }}>
                  <ButtonPaper
                    loading={loadingMax}
                    buttonColor="silver"
                    labelStyle={{ color: "white" }}                    
                    onPress={() =>
                      changeLance({ valor : 0, lance_maximo: true })
                    }
                  >
                    Lance Alvo
                  </ButtonPaper>
                </Card.Actions>}
                <ButtonPaper
                    icon={'cash-check'}                    
                    loading={loadingLanceReload}
                    buttonColor={theme.colors.tertiary}
                    labelStyle={{      
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: 18,                                                                                      
                     }}                                                                              
                  >
                     Total do Lance: 
                     {lanceFinal ?
                       (lanceFinal).toLocaleString("pt-br", {
                         style: "currency",
                         currency: "BRL",
                       })
                       :
                       "R$ 0,00"
                     }
                     
                  </ButtonPaper>                             
              {currentSale?.LANATU > 0 &&
                <Text
                  style={{
                    color: theme.colors.primary,
                    fontSize: 18,
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}
                >
                  Lance Alvo: R$
                  {currentSale?.LANMAX}
                </Text>
              }
              {errorModal && (
                <>
                  <Text style={{ color: "red" }}>{errorModalMsg}</Text>
                </>
              )}
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Button
                style={{ marginRight: 'auto', 
                backgroundColor: 'white', 
                color: 'black', 
                width: '50%',
               }}
                startIcon={
                  <Cancel/>
                }                                            
                onClick={hideModal}>
                Cancelar
              </Button>
              <Button
                style={{ marginRight: 'auto', 
                backgroundColor: theme.colors.primary, 
                color: 'white', 
                width: '50%',
                }}
                startIcon={
                  <Check/>
                }                                            
                onClick={() => handleSubmit(idLote, lanceFinal, 'N')}>
                Confirmar
              </Button>                                          
            </View>
          </Modal>
          <AlertModal
              visible={modalStatusCliente}
              message={"Entre em contato com nossa equipe, para validar os dados de seu cadastro."}
              contact={true}
              onClose={handleCloseStatusCliente}
            />
        </Portal>        
      </Provider>
     
    </View >
  );
}

