import "react-native-gesture-handler";
import React from "react";

import {
  Provider as PaperProvider,
  adaptNavigationTheme,
} from "react-native-paper";
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import Routes from "./src/Routes/routes";
import { theme } from "./src/Global/Theme";
import { StatusBar } from "expo-status-bar";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import GlobalContext from "./src/Context";
import { QueryClient, QueryClientProvider } from "react-query";
import CheckDocument from "./src/Screens/RecoveryPassword/CheckDocument/CheckDocument";
import ChangePassword from "./src/Screens/RecoveryPassword/ChangePassword/ChangePassword";

const Stack = createNativeStackNavigator();
const queryClient = new QueryClient();
const { LightTheme } = adaptNavigationTheme({
  reactNavigationLight: DefaultTheme,
  reactNavigationDark: DefaultTheme,
});
export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalContext>
        <PaperProvider theme={theme}>
          <NavigationContainer theme={LightTheme}>
            <StatusBar style="light" backgroundColor={theme.colors.primary} />
            <Stack.Navigator initialRouteName="Macedo Leilões Rurais">
              <Stack.Screen
                name="Macedo Leilões Rurais"
                component={Routes}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CheckDocument"
                component={CheckDocument}
                options={{ headerShown: false }}                
              />
              <Stack.Screen
                name="ChangePassword"
                component={ChangePassword}
                options={{ headerShown: false }}                
              />        
            </Stack.Navigator>
          </NavigationContainer>
        </PaperProvider>
      </GlobalContext>
    </QueryClientProvider>

  );
}
function createStackNavigator() {
  throw new Error("Function not implemented.");
}