import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { View, Text, Modal, Image, StyleSheet, Alert } from 'react-native';
import { Avatar, Button, ButtonGroup, Chip, Icon } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import CancelIcon from '@mui/icons-material/Cancel';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { setLance } from '../Services/api/AuthServices';
import { ListClients } from './ListClients';
import NotaPrint from './Prints/NotaPrint';
import NotaVirtual from './Prints/NotaVirtual';

const SaleModal = ({ visible, closeModal, selectedItem, formatTime, time, leilao, medias }) => {
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [lanceAtual, setLanceAtual] = useState(selectedItem?.LANATU || 0);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);

    const handleClose = () => {
        setLanceAtual(0);
        closeModal();
    };

    useEffect(() => {
        if (selectedItem) {
            setLanceAtual(selectedItem.LANATU || 0);
        }
    }, [selectedItem]);

    const handleLance = async () => {
        const data = {
            id: 0,
            idlote: `${selectedItem?.ID}`,
            datalance: new Date().toISOString(),
            idcliente: `${selectedClientId}`,
            ip: '',
            origem: "VENDIDO",
            valor: lanceAtual,
        };

        try {
            await setLance(data);
            closeModal(); 
        } catch (error) {
            console.error('Error setting lance:', error);
        }
    };

    const confirmLance = () => {
        setConfirmModalVisible(true); 
    };

    const handleConfirm = () => {
        handleLance();
        setConfirmModalVisible(false); 
    };

    const handleCancel = () => {
        setConfirmModalVisible(false); 
    };

    const incrementLance = (value) => {
        setLanceAtual(prev => prev + value);
    };

    const handleClientSelection = (id) => {
        setSelectedClientId(id);
        console.log(`Cliente selecionado ID: ${id}`);
    };

    return (
        <Modal visible={visible} transparent={false} animationType="slide">
            <View 
                style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f8f9fa',
                    padding: 10,
                }}
            >
                <ScrollView
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    style={{ flexDirection: 'row' }}                    
                >                    
                    <Chip
                      style={{ margin: 5, width: 150 }}                    
                      label={`Vendidos: ${medias[0]?.QTD || 0}`} // Acessa o primeiro item, se existir
                      icon={<GavelIcon />}
                    />
                    <Chip
                        style={{ margin: 5, width: 150 }}
                        label="Retirados: 1"
                        icon={<CancelIcon />}
                        variant="outlined"
                    />
                    <Chip
                        style={{ margin: 5, width: 250 }}
                        label={`Total Faturamento: R$ ${medias[0]?.TOTGERAL.toFixed(2) || 0}`} // Formatação de moeda
                        variant="outlined"
                    />
                    {medias.map((item, index) => (
                        <Chip
                            key={index}
                            style={{ margin: 5, width: 350 }}
                            label={`Média: ${item.RACAS} - R$ ${item.MEDIA.toFixed(2)}`} // Formatação de moeda
                            variant="outlined"
                        />
                    ))}
                                    
                </ScrollView>
            </View>
            <View style={styles.modalContainer}>
                <View style={styles.columnContainer}>
                    <Avatar style={{ margin : 10 }}>{selectedItem?.LOTEXX}</Avatar>
                    <View style={styles.textContainer}>
                        <Text style={styles.modalText}>{selectedItem?.DESLOT}</Text>
                        <Text style={styles.modalText}>Tempo em venda: {formatTime(time)}</Text>                
                    </View>
                </View>
                <Button
                    style={{ margin: 10, backgroundColor: 'firebrick', color: 'white' }}
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<YouTubeIcon />}                    
                >
                    Assistir Vídeo
                </Button>
                <Image 
                    source={{ uri: `https://macedo.s3.us-east-2.amazonaws.com/lote_1_img_${selectedItem?.ID}.jpg` }} 
                    style={styles.imageVenda} 
                />
                
                <View style={styles.infoContainer}>
                    <Text style={styles.modalLabel}>Filiação: {selectedItem?.FILIACAO || 'N/A'}</Text>
                    <Text style={styles.modalLabel}>Pelagem: {selectedItem?.PELAGE || 'N/A'}</Text>
                    <Text style={styles.modalLabel}>RP: {selectedItem?.RPXXX || 'N/A'} - SBB: {selectedItem?.SBBXXX || 'N/A'}</Text>
                    <Text style={styles.modalLabel}>
                        Categoria: {selectedItem?.CATEGO === "M" ? 'MACHO' : 
                                    selectedItem?.CATEGO === "F" ? 'FEMEA' :
                                    selectedItem?.CATEGO === "C" ? 'CASTRADO' :
                                    selectedItem?.CATEGO === "S" ? 'COBERTURA' : 'N/A'}
                    </Text>
                    <Text style={styles.modalLabel}>Comentário: {selectedItem?.COMENTARIO || 'N/A'}</Text>
                </View>

                <View style={styles.bidContainer}>
                    <Text style={styles.currentBidText}>Lance Atual</Text>
                    <Text style={styles.currentBidAmount}>
                        R${lanceAtual.toFixed(2)}
                    </Text>
                </View>
                
                <ButtonGroup variant="outlined" aria-label="Basic button group">
                    <Button style={styles.buttonGreen} onClick={confirmLance} startIcon={<GavelIcon />}>Bater Martelo</Button>                                        
                    <NotaPrint leilao={leilao} lote={selectedItem} lance={lanceAtual}/>
                    <NotaVirtual leilao={leilao} lote={selectedItem} lance={lanceAtual}/>
                    <Button style={styles.buttonWhite} onClick={() => incrementLance(10)} startIcon={<LibraryAddIcon />}>R$10,00</Button>
                    <Button style={styles.buttonWhite} onClick={() => incrementLance(20)} startIcon={<LibraryAddIcon />}>R$20,00</Button>
                    <Button style={styles.buttonWhite} onClick={() => incrementLance(50)} startIcon={<LibraryAddIcon />}>R$50,00</Button>
                    <Button style={styles.buttonWhite} onClick={() => incrementLance(100)} startIcon={<LibraryAddIcon />}>R$100,00</Button>
                    <Button style={styles.buttonRed} onClick={closeModal} startIcon={<RemoveDoneIcon />}>Remover da Venda</Button>
                    <Button style={styles.buttonFirebrick} onClick={closeModal} startIcon={<CancelIcon />}>Voltar aos Lotes</Button>
                </ButtonGroup>
            </View>

            {/* Confirmation Modal */}
            <Modal visible={confirmModalVisible} transparent={true} animationType="slide">
                <View style={styles.confirmModalContainer}>
                    <Text style={styles.confirmText}>Escolha o Comprador na lista abaixo</Text>
                    <ListClients onClientSelect={handleClientSelection} />                                        
                </View>
                <Button 
                    style={styles.buttonWhite}
                    onClick={handleConfirm} 
                    startIcon={<GavelIcon/>}
                >
                    Confirmar
                </Button>
                <Button 
                    style={styles.buttonFirebrick}
                    onClick={handleCancel} 
                    startIcon={<CancelIcon/>}
                >
                    Cancelar
                </Button>
            </Modal>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: 20,
        height: '90%',
    },
    modalLabel: {
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold',
    },
    modalText: {
        fontSize: 16,
        color: 'white',
        marginVertical: 5,
        textAlign: 'left',
    },
    imageVenda: {
        width: 300,
        height: 300,
        borderRadius: 10,
        marginVertical: 10,
    },
    infoContainer: {
        margin: 10,
        width: '70%',
        alignItems: 'flex-start',
    },
    bidContainer: {
        alignItems: 'center',
        marginBottom: 20,
    },
    currentBidText: {
        fontSize: 40,
        fontWeight: 'bold',
        color: 'green',
    },
    currentBidAmount: {
        fontSize: 60,
        fontWeight: 'bold',
        color: 'white',
    },
    buttonGreen: {
        backgroundColor: 'green',
        color: 'white',
    },
    buttonRed: {
        backgroundColor: 'red',
        color: 'white',
    },
    buttonWhite: {
        backgroundColor: 'white',
        color: 'black',
    },
    buttonFirebrick: {
        backgroundColor: 'firebrick',
        color: 'white',
    },
    confirmModalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: 20,
    },
    confirmText: {
        fontSize: 20,
        color: 'white',
        marginBottom: 20,
        textAlign: 'center',
    },
    columnContainer: {
        flexDirection: 'row',
        alignItems: 'center', // Alinha os itens verticalmente
        margin: 10,
    },
});

export default SaleModal;
