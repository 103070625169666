import React, { useContext, useEffect, useState } from "react";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import Menu from "../../components/Gerais/Menu/Menu";
import { Button, Typography } from "@mui/material";
import { theme } from "../../Global/Theme";
import { Appbar } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import { View } from "react-native";
import ArticleIcon from "@mui/icons-material/Article";
import { getClienteID, getLancesporCliente } from "../../Services/api/AuthServices";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import PriceCheck from "@mui/icons-material/PriceCheck";
import PriceChange from "@mui/icons-material/PriceChange";

export default function DetailsScreen() {
  const { setState, state } = useContext(AuthContext);
  const [ cliente, setCliente ] = useState<any | null>([]);
  const [ lances , setLances ] = useState<any | null>([]);
  const [ loading, setLoading ] = useState(false);
  const [ loadingLances, setLoadingLances ] = useState(false);

  async function fetchCliente() {   
    setLoading(true);   
    const data = await getClienteID(state.user.id);
    setCliente(data[0]);
    setLoading(false);
  }

  async function fetchLances() {   
    setLoadingLances(true);   
    const data = await getLancesporCliente(state.user.id);
    setLances(data);
    setLoadingLances(false);
  }

  useEffect(() => {
    fetchCliente();    
  }, [1])
    
   
 
  return (
    <View style={{ flex: 1, backgroundColor: theme.colors.secondary, minHeight: '800px' }}>
      <Menu/>
      <Appbar
          style={{
            height: '70px',
            width: '100%',
            backgroundColor: theme.colors.secondary,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          >
          <ArticleIcon
          style={{
            color: theme.colors.tertiary,
            borderRadius: 50,   
            margin: 10         
          }}          
          />        
          <Typography color={'black'}>DADOS DO CLIENTE</Typography>
      </Appbar>          
      <ScrollView 
        showsHorizontalScrollIndicator={false} 
        contentContainerStyle={{
          margin: 15,
          backgroundColor: theme.colors.secondary,                
      }}>        
        <Typography variant="h5" gutterBottom>
          {cliente.nomexx}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Email: {cliente.emailx}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          CPF/CNPJ: {cliente.cnpjxx || cliente.cpfxxx}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          CEP: {cliente.cepxxx}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Endereço: {cliente.endere}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Bairro: {cliente.bairro}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Cidade/Estado: {cliente.nomecidade} / {cliente.nomeestado}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Telefone: {cliente.telres}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Celular 1: {cliente.celu_1}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Celular 2: {cliente.celu_2}
        </Typography>                                
        <Typography variant="subtitle1" gutterBottom>
          Pai: {cliente.paixxx}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Mãe: {cliente.maexxx}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Profissão: {cliente.profiss}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Empresa: {cliente.empres}
        </Typography>

        <Button
          style={{ 
                    backgroundColor: theme.colors.primary, 
                    color: 'white', 
                    textAlign: 'center',
                    width: '100%',                                            
                    margin: 10 }}
          startIcon={
            <PriceCheck/>
          } 
          onClick={() => fetchLances()}
        >
          Meus Lances
        </Button>
        <View
        >
          
          {lances.map((lance: any) => (
             <Button
             style={{ 
                       backgroundColor: 'silver', 
                       color: 'white', 
                       textAlign: 'center',
                       width: '100%',                                            
                       margin: 10 }}
             startIcon={
               <PriceChange/>
             } 
             onClick={() => fetchLances()}
           >
             Lote: {lance.LOTEXX} Descrição: {lance.DESLOT} <br></br> Leilão: {lance.LEILAO} - Valor: {lance.VALOR.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}
           </Button>            
          ))}
          
        </View>



     
      </ScrollView>
      <HeaderBotton></HeaderBotton>        
    </View>
  );
}
