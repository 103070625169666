import { View } from "react-native";
import {
  StyleSheet
} from "react-native";
import React, { useState } from "react";
import { TextInput, Text, Button } from "react-native-paper";
import { useNavigation, useRoute } from "@react-navigation/native";
import { updatePassword } from "../../../Services/api/AuthServices";
import { theme } from "../../../Global/Theme";
import Menu from "../../../components/Gerais/Menu/Menu";
import HeaderBotton from "../../../components/HeaderBottom/HeaderBottom";
import { ScrollView } from "react-native-gesture-handler";
import Alert from '@mui/material/Alert';
import { Collapse, IconButton } from "@mui/material";

const styles = StyleSheet.create({
  container: {
    marginTop: 0,
    flex: 1,
    justifyContent: "flex-start",
  },
  title: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  textInput: {
    backgroundColor: 'white',    
    width: '60%',    
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  imageTop: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    width: "100%",
    height: 250,
  },
  buttonInput: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    width: '50%'
  },
  error: {
    color: "red",
    fontSize: 16,
  },
});

export default function ChangePassword(props) {  
  const [password, setPassword] = useState("");
  const [new_password, setnew_Password] = useState("");
  const [loading, setLoading] = useState(false);
  const route = useRoute();
  const navigation = useNavigation<any>();
  const handleSignInScreens = () => {
    navigation.navigate('HomeScreen');  
  };
  const [idUser, setIdUser] = useState(route.params);
  const [open, setOpen] = React.useState(false);

  function handleTwoPassword(old_Password, new_Password) {
    let valida = false;
    if (old_Password != new_Password) {
      valida = false;      
      <Alert severity="warning">
        Senha não são identicas.Corriga por favor
      </Alert>      

    }
    else {
      valida = true
    }
    return valida;
  }

  async function handleUpdatePassword(newPassword) {
    try {      
      setLoading(true);
      await updatePassword(JSON.stringify(idUser), newPassword);
      setLoading(false);
      setOpen(true);
      handleSignInScreens;
    } catch (error) {      
      return false      
      setLoading(false);
      setOpen(false);
    }
  }
  return (
    <View style={{ minHeight: '100vh'}}>
    <Menu/>      
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}
      >
        <Text
          style={{ textAlign: "center", marginTop: 10, color: theme.colors.primary }}
          variant="titleLarge"
        >
          Alterar a Senha
        </Text>
        <TextInput
          style={styles.textInput}
          mode="flat"
          label="Nova Senha"
          secureTextEntry
          value={password}
          onChangeText={(value) => setPassword(value)}
        />
        <TextInput
          style={styles.textInput}
          mode="flat"
          label="Repita a Senha"
          onBlur={value => handleTwoPassword(password, value)}
          secureTextEntry
          value={new_password}
          onChangeText={(value) => setnew_Password(value)}
        />
        <Button
          mode="contained"
          loading={loading}
          style={styles.buttonInput}
          onPress={() => handleUpdatePassword(password)}
        >
          Salvar Nova Senha
        </Button>          
        <Collapse style={{ 
                    width: '50%', 
                    marginLeft: 'auto', 
                    marginRight: 'auto', 
                    marginTop: '10px' 
                  }} in={open}
        >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Senha alterada com Sucesso
        </Alert>        
        </Collapse>
      </ScrollView>
      <HeaderBotton />
    </View>
  );
}
