import { Text, View } from "react-native";
import React, { useContext, useEffect, useState } from "react";

import { ActivityIndicator, Appbar } from "react-native-paper";
import { Leilao } from "../../utils/types/Leilao.type";
import CardLeilao from "../../components/Cards/CardLeilao/CardLeilao";
import { ScrollView } from "react-native-gesture-handler";
import { getLeiloes } from "../../Services/api/AuthServices";
import { theme } from "../../Global/Theme";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import Menu from "../../components/Gerais/Menu/Menu";
import { Typography } from "@mui/material";
import moment from "moment";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import ArticleIcon from "@mui/icons-material/Article";

class HHTTPError extends Error { }

export default function ProductsScreen({ navigation }) {
  const { setState, state } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [leiloes, setLeiloes] = useState<Leilao[] | null>([]);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", async () => {
      try {
        setIsLoading(true);
        const data = await getLeiloes();
        function compare(a,b) {
          const data1 = moment(a.DATLEI).format('MM-DD-YYYY');
          const data2 = moment(b.DATLEI).format('MM-DD-YYYY');
          return  data1 > data2 ? 1 : -1;
        }
        
        const reverseData = data.sort(compare);
        setLeiloes(reverseData);
        
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(true);
      }
    });
    return unsubscribe;
  }, [navigation]);

  return (
    <View>
    <Menu/>      
      {isLoading ? (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <ActivityIndicator animating={true} color={theme.colors.primary} />
          <Text>Buscando Leilões...</Text>
        </View>
      ) : (
        <View style={{ flex: 1 , minHeight: '560px'}} >
          <Appbar
          style={{
            height: '70px',
            width: '100%',
            backgroundColor: theme.colors.secondary,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          >
          <ArticleIcon
          style={{
            color: theme.colors.tertiary,
            borderRadius: 50,   
            margin: 10         
          }}          
          />
          <Typography translate='no' color={'black'}>PRÓXIMOS LEILÕES</Typography>
          </Appbar>      
          <ScrollView 
              horizontal={true} 
              showsHorizontalScrollIndicator={true} 
              contentContainerStyle={{ flexGrow: 1, justifyContent: 'center',height: '500px', }}
              style={{ 
                backgroundColor: theme.colors.secondary, 
                flex: 1,
                marginBottom: '20px',                
              }}>

            {error ? (
              <>
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ActivityIndicator animating={true} color="white" />
                  <Text>Tivemos problemas.Tente novamente...</Text>
                </View>
              </>
            ) : (
              <>
              
                {leiloes.map((lote) => (
                  <CardLeilao key={lote.ID} leilao={lote} route={'products'} />
                ))}
              
              </>
            )}
          </ScrollView>
          <HeaderBotton></HeaderBotton>
        </View>
      )}
    </View>
  );
}
