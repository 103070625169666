import * as yup from "yup";

const formatCPF = (value) => {  
  const cleanedValue = value.replace(/\D/g, '');
  if (cleanedValue.length !== 11) {
    return value; 
  }
    
  return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const SignUpSchema = yup.object().shape({
  email: yup
    .string()
    .email("O e-mail é inválido!")
    .required("O e-mail é obrigatório!"),
  senha: yup.string().required("A senha é obrigatória!"),
  cpfcgc: yup
    .string()
    .transform((value) => formatCPF(value))
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF XXX.XXX.XXX-XX')
    .required('CPF is required'),  
  cel1: yup.string().required("Celular é obrigatório"),
  cidade: yup.string(),
  nome: yup.string().required("Nome é obrigatório"),
  datnas: yup.string().transform((value, originalValue) => {    
    // originalValue = "10/12/2022"
    try {
      let date = originalValue.split('/')
      // date = ["10", "12", "2022"] <- day, month and year respectively

      if (date.length === 3) {
        let newDate = `${date[2]}-${date[1]}-${date[0]}`
        return newDate
      }
      return null
    } catch (e) {
      return null
    }
  }),
  rg: yup.string().required("RG/CNH é obrigatório"),
  pai: yup.string(),
  mae: yup.string(),
  cep: yup.string().required("CEP é obrigatório"),
  endereco: yup.string(),
  bairro: yup.string(),
  telcom: yup.string(),
  telres: yup.string(),
  cel2: yup.string(),
  banco: yup.string(),
  agencia: yup.string(),
  conta: yup.string(),
  propri: yup.string(),
  gta: yup.string(),
  endpro: yup.string(),
  profiss: yup.string(),
  empres: yup.string(),
  renda: yup.number(),
  refer1: yup.string(),
  telrefer1: yup.string(),
});
