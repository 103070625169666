import { View } from "react-native";
import {
  StyleSheet,
  Alert,
} from "react-native";
import React, { useState } from "react";
import { TextInput, Text, Button } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import { checkDocument } from "../../../Services/api/AuthServices";
import Menu from "../../../components/Gerais/Menu/Menu";
import HeaderBotton from "../../../components/HeaderBottom/HeaderBottom";
import { theme } from "../../../Global/Theme";
import { ScrollView } from "react-native-gesture-handler";

const styles = StyleSheet.create({
  container: {
    marginTop: 0,
    flex: 1,
    justifyContent: "flex-start",
  },
  title: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  textInput: {
    backgroundColor: 'white',    
    width: '60%',    
    marginLeft: 'auto',
    marginRight: 'auto',    
  },
  imageTop: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    width: "100%",
    height: 250,
  },
  buttonInput: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    width: '50%'
  },
  error: {
    color: "red",
    fontSize: 16,
  },
});

export default function CheckDocument() {
  const [document, setDocument] = useState("");
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation<any>()


  async function handleCheckDocument(document: string) {
    try {
      setLoading(true);

      const user = await checkDocument(document);      
      navigation.navigate('ChangePassword', user.id);      
      setLoading(false);
      //navigation.navigate(handleChangePassword);
    } catch {
      Alert.alert("Erro", "Usuário não encontrado");
      setLoading(false);
    }
  }
  return (
    <View style={{ minHeight: '100vh'}}>
    <Menu/>      
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}
      >
        <Text
          style={{ textAlign: "center", marginTop: 10, color: theme.colors.primary }}
          variant="titleMedium"
        >
          Recuperar Senha
        </Text>
        <TextInput        
          style={styles.textInput}
          mode="flat"
          label="E-mail/CPF"
          placeholder="adicione pontuações e caracateres especiais"
          value={document}
          onChangeText={(value) => setDocument(value)}
        />
        <Button
          mode="contained"
          icon="login"
          loading={loading}
          style={styles.buttonInput}
          onPress={() => handleCheckDocument(document)}
        >
          Verificar Login
        </Button>
      </ScrollView>
      <HeaderBotton />
    </View>
  );
}
