import React, { useEffect, useState } from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from '../../hooks/useCountdown';
import { theme } from '../../Global/Theme';
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Button } from '@mui/material';
import { View } from 'react-native';

const ExpiredNotice = () => {
  return (
    <View
      style={{
        marginRight: 10,
        marginLeft: 10,        
      }}
    >   
    <Button
       style={{ 
       backgroundColor: theme.colors.primary, 
       color: 'black', 
       textAlign: 'center',
       marginLeft: 10,
       width: '99%'
      }}
       startIcon={
         <WatchLaterIcon/>
       }                                                   
    >                            
     ENCERRADO
    </Button>                                                                                 
    </View>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <View
      style={{
        marginRight: 10,
        marginLeft: 10,        
      }}
    >   
    <Button
        style={{            
        backgroundColor: theme.colors.primary, 
        textAlign: 'center',
        
        color: 'black',         
       }}
        startIcon={
          <AccessTimeIcon/>
        }                                                   
    >                            
    <DateTimeDisplay value={days} type={days > 1 ? 'dias ' : 'dia '}/> 
    <DateTimeDisplay value={hours} type={hours > 1 ? 'horas ' : 'hora '}/> 
    <DateTimeDisplay value={minutes} type={minutes > 1 ? 'minutos ' : 'minuto '}/> 
    <DateTimeDisplay value={seconds} type={seconds > 1 ? 'segundos ' : 'segundo '}/> 
    </Button>                                                                                         
    </View>
  );
};

const CountdownTimer = ({ targetDate, onEncerradoChange }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  const [encerrado, setEncerrado] = useState(false);

  useEffect(() => {
    const isEncerrado = days + hours + minutes + seconds <= 0;
    if (encerrado !== isEncerrado) {
      setEncerrado(isEncerrado);
      onEncerradoChange(isEncerrado);
    }
  }, [days, hours, minutes, seconds, encerrado, onEncerradoChange]);


  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
